import { h } from 'preact'
import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import PhoneEnabledIcon from '@material-ui/icons/PhoneEnabled'
import Paper from '@material-ui/core/Paper';
import { IRecordData } from '../core/RecordItem/definition';
import { IContactData, IContactListProps } from './definition';
import useSharedStyles from '../../style/theme'
import RecordItem from '../core/RecordItem/RecordItem';
import LoadingListItem from '../core/LoadingListItem/LoadingListItem';
import { ContactsDataDummy } from '../../utils/Data/contactsData'
import { Collapse, Grid } from '@material-ui/core'
import { useState } from 'preact/hooks'
import PhoneItem from '../core/phoneItem/phoneItem'

export default function ContactList(props: IContactListProps) : any
{
  const classes = useSharedStyles();
  const [expanded, setExpanded] = useState(false);
  const [selectedBtn, setSelectedBtn] = useState('');
  const [valueSelected, setValueSelected] = useState({} as IContactData);
  const iconButtton = <PhoneEnabledIcon color="primary" />


  const contactsListsHandlePhone = (data:any, button:string, phoneSelect:string) => () => {
    if(button == "onlyPhoneWork"){
      const dataContactList: IRecordData = { name: data.name, phoneNumber: phoneSelect, type: data.type, idCrm: data.idCrm };
      props.handlePhoneClick(dataContactList)
    }
    else if(button == "morePhone" || button == "twitter"){
      setValueSelected(data)
      setSelectedBtn(button)
      setExpanded(!expanded)
    }
    // else if(button == "twitter"){
    //   setValueSelected(data)
    //   setSelectedBtn(button)
    //   setExpanded(!expanded)
    // }
	}
  if (props.callInProgress) {
    return <div style={{ visible: 'none' }}></div>
  }
  if (!props.searchContact && props.contacts.length === 0) {
    return (
      <div className={classes.root}>
        <Paper elevation={3} className={classes.rootPaper}>
            <h5 className={classes.message}>No hay datos disponibles.</h5>
        </Paper>
      </div>
    );
  }
  return (
    <Paper className={classes.rootDiv}>
      {/* <List dense id="RootDiv-Contac" className={[classes.rootDiv, classes.root].join(' ')} style={{marginLeft: '5%'}} > */}
      {props.searchContact && ContactsDataDummy && ContactsDataDummy.length > 0 ?
        ContactsDataDummy.map(dummy => {
          return (
            <ListItem key={dummy.idCrm}>
              <LoadingListItem />
            </ListItem>
          )
        })
        :
        <List dense  style={{ paddingTop: "0px", paddingBottom: "0px" }}>
          {props.contacts.map((value: IContactData) => {
            return (
              <div key={value.idCrm}>
                <ListItem  style={{ paddingTop: "5px", paddingBottom: "5px" }}>
                  <RecordItem record={value} from='Home' content={iconButtton} callBack={contactsListsHandlePhone} />
                </ListItem>
                {/* <ListItem key={value.idCrm} button style={{ minHeight: '45px' }}> */}
                <Collapse in={value.idCrm == valueSelected.idCrm ? expanded : false} timeout="auto" unmountOnExit 
                style={{ padding: "10px" }}
                >
                  {selectedBtn == "morePhone" &&
                    <Grid container spacing={4}
                      justifyContent="flex-start"
                      alignItems="flex-start" className={classes.gridPhone}>
                      {(valueSelected.phoneWork != "" && valueSelected.phoneWork != undefined) &&
                        <PhoneItem contactData={valueSelected} label="Phone Work" phoneNumer={valueSelected.phoneWork} callBack={props.handlePhoneClick} />
                      }
                      {(valueSelected.phoneMobile != "" && valueSelected.phoneMobile != undefined) &&
                        <PhoneItem contactData={valueSelected} label="Phone Mobile" phoneNumer={valueSelected.phoneMobile} callBack={props.handlePhoneClick} />
                      }
                      {(valueSelected.phoneHome != "" && valueSelected.phoneHome != undefined) &&
                        <PhoneItem contactData={valueSelected} label="Phone Home" phoneNumer={valueSelected.phoneHome} callBack={props.handlePhoneClick} />
                      }
                    </Grid> 
                  }
                </Collapse>
                {/* </ListItem> */}
                </div>
                )
              })}
              </List>
      }
    </Paper>
  );
}