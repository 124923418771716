import { h } from 'preact';
import { createStyles, makeStyles, Theme, withStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import Badge from '@material-ui/core/Badge';
import { IAppBarData } from './interfaces';
import Menu, { MenuProps } from '@material-ui/core/Menu';
import { ClickAwayListener, IconButton, Tooltip } from '@material-ui/core';
import NotificationImportantIcon from '@material-ui/icons/NotificationImportant';
import { useEffect, useState } from 'preact/hooks';
const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      flexGrow: 1,
    },
    menuButton: {
      marginRight: theme.spacing(2),
    },
    title: {
      flexGrow: 1,
      marginLeft: "1em"
    },
    versionAppText: {
      marginRight: "0px",
      marginLeft: "auto"
    },
    avatar: {
      color: "black",
      backgroundColor: "#EEEEEE",
      padding: "1em",
    },
  }),
);

const StyledBadge = withStyles((theme: Theme) =>
  createStyles({
    badge: {
      backgroundColor: '#44b700',
      color: '#44b700',
      boxShadow: `0 0 0 2px ${theme.palette.background.paper}`,
      '&::after': {
        position: 'absolute',
        top: 0,
        left: 0,
        width: '100%',
        height: '100%',
        borderRadius: '50%',
        animation: '$ripple 1.2s infinite ease-in-out',
        border: '1px solid currentColor',
        content: '""',
      },
    },
    '@keyframes ripple': {
      '0%': {
        transform: 'scale(.8)',
        opacity: 1,
      },
      '100%': {
        transform: 'scale(2.4)',
        opacity: 0,
      },
    },
  }),
)(Badge);

const NotificationBadge = withStyles(() =>
  createStyles({
    badge: {
      right: -3,
      top: 13,
      padding: '0 4px',
    },
  }),
)(Badge);

// const StyledMenu = withStyles({
//   paper: {
//     border: '1px solid #d3d4d5',
//   },
// })
((props: MenuProps) => (
  <Menu
    elevation={0}
    getContentAnchorEl={null}
    anchorOrigin={{
      vertical: 'bottom',
      horizontal: 'center',
    }}
    transformOrigin={{
      vertical: 'top',
      horizontal: 'center',
    }}
    {...props}
  />
));

export default function CtiAppBar(props: IAppBarData) : any 
{
  const classes = useStyles();
  const [userHasPBXKey, setuserHasPBXKey ] = useState(false)
  const [count, setCount] = useState(1);
  const [toolTipOpen, setToolTipOpen] = useState(false);
  // const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  // const [status, setStatus] = useState('online');

  // const handleChange = (event: React.ChangeEvent<any>) => {
  //   setStatus(event.target.value);
  // };

  // const handleClick = (event: React.MouseEvent<HTMLElement>) => {
  //   setAnchorEl(event.currentTarget);
  // };
  // const handleClose = () => {
  //   setAnchorEl(null);
  // };

  // const selectConfig = () => {
  //   setAnchorEl(null);
  //   props.callbackConfigOpen(true)
  // };
useEffect(()=>{
  if(props.userAccessData.userPbxKey){
    setuserHasPBXKey(true)
  }
}, [props.userAccessData])


const handleTooltipClose = () => {
  setToolTipOpen(false);
};

const handleTooltipOpen = () => {
  if(count >= 1){
    setCount(0)
  }
  setToolTipOpen(true);
};

  return (
    <AppBar position="static" style={{ backgroundColor: "rgba(0, 107, 202)" }}>
    {/* <AppBar position="static" style={{ backgroundColor: "#3C14C8" }}> */}
     <Toolbar style={{ minHeight: "0px" }}>
        <StyledBadge
          overlap="circular"
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'right',
          }}
          variant="dot"
          invisible={!props.online}
        />  
        {props.online ?
          <Typography variant="subtitle2" className={classes.title}> {props.name}</Typography>
          :
          <span style={{ display: "contents" }}>
            <Typography variant="subtitle2" >Offline</Typography>
            <ClickAwayListener onClickAway={handleTooltipClose}>
              <div>
                <Tooltip
                  PopperProps={{
                    disablePortal: true,
                  }}
                  onClose={handleTooltipClose}
                  open={toolTipOpen}
                  disableFocusListener
                  disableHoverListener
                  disableTouchListener
                  title={!userHasPBXKey && props.userAccessData != undefined &&props.userAccessData.userId ? "Your User does not have a PBX key, contact your administrator." : "There is no connection to the PBX, Please contact your administrator."}
                >
                  <IconButton aria-label="notificacion"  style={{ height: "0px" }}  onClick={handleTooltipOpen}>
                    <NotificationBadge badgeContent={count} color="secondary" showZero>
                      <NotificationImportantIcon style={{ color: "white" }} />
                    </NotificationBadge>
                  </IconButton>
                </Tooltip>
              </div>
            </ClickAwayListener>
          </span>
        }

        <Typography variant="subtitle2" className={classes.versionAppText}>{props.appVersion}</Typography>
        {/* {props.isAppOpen && <ExpandMoreIcon />}  */}
        
        {/* Posibles Ajustes para realize 2, configurar panel de conexion a platforms y estados */}

        {/* <IconButton
          aria-controls="customized-menu"
          aria-haspopup="true"
          style={{ padding: "5px", color: "white" }}
          aria-label="open"
          onClick={handleClick}
        >
          <MenuIcon />
        </IconButton> */}
        {/* <StyledMenu
          id="customized-menu"
          anchorEl={anchorEl}
          keepMounted
          open={Boolean(anchorEl)}
          onClose={handleClose}
        > 

          {/* <FormControl component="fieldset">
            <Typography variant="caption" className={classes.title}> Estatus</Typography>
            <RadioGroup aria-label="gender" name="gender1" value={status} onChange={handleChange}>
              <FormControlLabel value="online" control={<Radio />} label="online" />
              <FormControlLabel value="ausente" control={<Radio />} label="ausente" />
              <FormControlLabel value="offline" control={<Radio />} label="offline" />
            </RadioGroup>
          </FormControl> 
           <Divider />
          { !props.configOpen &&
            <MenuItem 
            disabled = {props.callStatus == "ringing" || props.callStatus == "hangup"}
            onClick={selectConfig}>
            <ListItemIcon>
              <SettingsIcon fontSize="small" />
            </ListItemIcon>
            <ListItemText primary="Ajustes" />
           </MenuItem>
          }
        </StyledMenu> */}
      </Toolbar>
    </AppBar>
  );
}