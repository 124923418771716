import { FunctionalComponent, h } from 'preact';
// import { Route, Router } from 'preact-router';

import AppG from '../views/app/index';
import { useEffect, useState } from 'preact/hooks';
import { init, initialize, login } from '../utils/socket.io';
import { IAppProps } from '../views/interfaces';
import { iframePlatformData } from '../utils/types/sugarCrm.modules.types';

const App: FunctionalComponent = () => {  
    const [hasConnectedSocket, setHasConnectedSocket] = useState(false);
    const [hasInit, setHasInit] = useState(false);
    const [loggedData, setLoggedData] = useState(null);
    const [platformData, setPlatformData] = useState({} as iframePlatformData);

    useEffect(()=> {
        if(window)
        {
            // Firts message to iframe
            const identify = {
                who: "app-cti",
                action: "getPlatformData",
                platform: "sugar"
            }
            window.parent.postMessage(identify, "*");

            // Get event listener
            window.addEventListener('message', async(msg) => {
                if(msg.data.from == "widgetIframeInitData"){
                    setPlatformData(msg.data.data)
                }
            }, false);
        }
    }, []);

    useEffect(() => {
        if (Object.keys(platformData).length != 0) {
            const tempAccessData: string = JSON.stringify(platformData);
            initialize(tempAccessData, setHasConnectedSocket);
        }
    }, [platformData]);

    useEffect(()=> {
        if(hasConnectedSocket)
        {
            init(setHasInit);
        }
    }, [hasConnectedSocket]);

    useEffect(()=> {
        if(hasInit)
        {
            // const savedLoggedData = sessionStorage.getItem("logged_data");
            //// Verificar que exista conexión en el servidor antes
            //// de cargar información de caché
            // if(!savedLoggedData || savedLoggedData == null)
                login(handleLogin);
            // else
            // {
            //     let val = null;
            //     try
            //     {
            //         val = JSON.parse(savedLoggedData);
            //     }
            //     catch(error)
            //     {
            //         val = savedLoggedData;
            //     }
            //     handleLogin(val);
            // }
        }
    }, [hasInit]);

    const handleLogin = (value: any) => {
        const sessionValue: any = (typeof value == "string" || typeof value == "boolean") ? value :  JSON.stringify(value);
        sessionStorage.setItem("logged_data", sessionValue);
        setLoggedData(value);
    }

    const appDataProps: IAppProps = {
        loginPbxStatus: loggedData,
        platformData: platformData
    }


    return (
        <div id="preact_root">
            {/* <Header /> */}
            <AppG {...appDataProps}/>
            {/* <Router>
                <Route path="/" component={Home} />
                <Route path="/profile/" component={Profile} user="me" />
                <Route path="/profile/:user" component={Profile} />
                <NotFoundPage default />
            </Router> */}
        </div>
    );
};

export default App;
