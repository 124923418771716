import { io, Socket } from 'socket.io-client';
import { ICall, INumberCodeCountry, IPBXCallParams, IRequest, IResponse, UhuuSocketEventTypes } from '@my-uhuu/cti_types';
import { IListeners } from '../controllers/communication.interface';
import { IContactCallData } from '../components/callBar/definition';
import { responsePlatform } from './types/platformSugarResponse';
import { IChildRelatedRecord, IParentRelatedRecord, IsugarAccess, IResultEventListener } from '../views/interfaces';

export let socket: Socket | null = null;
let newAccessData: any;
// Petición - Respuesta (cliente -> server) - Acknowledgment

// Eventos PBX

/**
 * Inicia comunicación física con el server
 */
export function initialize(accessData: string, callback: (value: boolean) => void): void
{
    const be = "https://voipbackqa.myuhuu.com";
    // const be = "https://voipback.myuhuu.com";
    if(accessData)
    {
        newAccessData = JSON.parse(accessData)
        socket = io(be, {
            withCredentials: true,
            extraHeaders: {
                authorization: "Bearer 6916496f6bcfbfb63865ea8780eacf6c",
                key: newAccessData.userPbxKey,
                userId: newAccessData.userId
            },
        });

        if (socket != null) {
            socket.on("connect", () => {
                //Detectar conexión exitosa
                console.log("Connected to server")
                callback(true);
            });

            socket.on("connect_error", (info: any) => {
                console.error("connect_error", info);
                callback(false);
            });
        }
    }
}

/**
 * Inicia comunicación lógica con el server
 */
export function init(callback: ((value: boolean) => void) | null = null): void
{
    if(socket != null)
    {
        const initBody: IRequest = {
            platform: "pbx",
            args: []
        }
        socket.emit("init", initBody, (data: IResponse) => {
            if(callback && data.success == true)
            {
                callback(true);
            }
        });
    }
}

/**
 * Suministra credenciales de autenticación
 */
export function login(callback: (value: any) => void): void
{
    if(socket != null)
    {
        if(newAccessData)
        {
            const loginBody: IRequest = {
                platform: "pbx",
                args: {
                    "key": newAccessData.userPbxKey
                }
            }
            socket.emit("login", loginBody, (data: IResponse) => {
                if(callback && data.success == true)
                {
                    callback(data);
                }
            });
        }
    }
}

export function testSocket() : void
{
    if (socket != null) {
        console.log("send getid")
        socket.emit("getid", (data: any) => {
            console.log(data)
        });
    }   
}
/**
 * Emitiendo evento enviando llamada(sendcall)
 */
export function sendcall(contactData:IContactCallData,  myext: string, callback: (value: any) => void,  numberCodeCountry?:INumberCodeCountry): void
{
    const bodyArgs: IPBXCallParams ={
            number: contactData.phoneNumber, 
            extensionCaller: myext
    }
    if(contactData.idCrm != '' && numberCodeCountry){    
        bodyArgs.number = String(numberCodeCountry.phoneNumber)
    }
    if(numberCodeCountry){
        bodyArgs.numberCodeCountry = numberCodeCountry
    }

    if (newAccessData && socket != null)
    {
        const sendCallBody: IRequest = {
            platform: "pbx",
            args: bodyArgs
        }
        socket.emit("sendcall", sendCallBody, (data: IResponse) => {
            if (callback && data.success == true) {
                callback(data);
            }
        });
    }
    else
    {
        //  solo para pruebas
        const data = {
            success: true,
        }
        callback(data);
    }
}


/**
 * Emitiendo evento contestando llamada(answercall)
 */
export function answercall(myext: string, callNumber: string, callback: (value: any) => void): void
{
    if (newAccessData && socket != null) {
        const answerCallBody: IRequest = {
            platform: "pbx",
            args:
            {
                number: callNumber, //obtener de indebido
                extensionCaller: myext
            }
        }
        socket.emit("answercall", answerCallBody, (data: IResponse) => {
            if (callback && data.success == true) {
                callback(data);
            }
        });
    } else {
        //  solo para pruebas
        const data = {
            success: true,
        }
        callback(data);
    }
}

 
/**
 * Emitiendo evento Colgando llamada(hangupcall)
 */
// export function hangupcall(myext: string, callNumber: string, callback: (value: any) => void): void
export function hangupcall(contactData:IContactCallData, myext: string, callback: (value: any) => void, numberCodeCountry?:INumberCodeCountry): void
{
    const bodyArgs: IPBXCallParams = {
        number: contactData.phoneNumber,
        extensionCaller: myext
    }
    if (contactData.idCrm != '' && numberCodeCountry) {
        bodyArgs.number = String(numberCodeCountry.phoneNumber)
    }
    if (numberCodeCountry) {
        bodyArgs.numberCodeCountry = numberCodeCountry
    }
    if (newAccessData && socket != null) {
        const hangupCallBody: IRequest = {
            platform: "pbx",
            args: bodyArgs
        }
        // socket.emit("hangupcall", hangupCallBody, (data: IResponse) => {
        socket.emit("hangupcall", hangupCallBody)
            // if (callback && data.success == true) {
            //     callback(data);
            // }
        // });
    } else {
        //  solo para pruebas
        // const data = {
        //     success: true,
        // }
        // callback(data);
    }
}

// Eventos sugarcrm
/**
 * Busqueda de contacto en lista de contactos de plataformas
 */
export async function searchcontact(searchRecord: string, callback: (value: any) => void): Promise<void>
{
    let response = {} as responsePlatform
    if (newAccessData && socket != null)
    {
        const sugarAccess = await resetToken(newAccessData);
        if (sugarAccess) {
            const platform = getPlatform(sugarAccess)
            const searchContactBody = {
                user: platform.auth.username,
                platform: platform,
                args: {
                    search: searchRecord
                }
            }
            // Solo para pruebas
            //  let data = filterIt(searchRecord);
            //     callback(data)
            // console.log("searchContactBody: ", searchContactBody);
            socket.emit("searchcontact", searchContactBody, (data: IResponse) => {
                // console.log("data searchcontact  Response: ", data);
                if (callback && data.success == true) {
                    const sugarData = data.data.success.find((p: any) => p.platform == "sugarcrm")
                    if (sugarData) {
                        response = { status: sugarData.result.status, data: sugarData.result.data.records }
                    } else {
                        response = { status: 404, data: {} }
                    }
                    callback(response);
                }
            });
        }
        else {
            response = { status: 404, data: {} };
            callback(response);
        }
    }
    else {
        response = { status: 404, data: {} };
        callback(response);
    }
}

/**
 * Busqueda de registros en el crm que coincidan con el numero de la llamada
 */
export async function searchrelatedrecords( contactCallData:IContactCallData, callback: (value: any) => void): Promise<void>
{
    let response = {} as responsePlatform
    if (newAccessData && socket != null)
    {
        const sugarAccess = await resetToken(newAccessData);
        if (sugarAccess) 
        {
            const platform = getPlatform(sugarAccess)
            const searchrelatedrecordsBody = {
                platform: platform,
                args: {
                    module: contactCallData.type,
                    recordId: contactCallData.idCrm,
                    relatedRecord: {
                        "module": "Calls",
                        "linkName": "calls"
                    }
                }
            }
            // // Solo para pruebas
            // let records = relatedRecords
            // let data = {
            //     success: true,
            //     data: {
            //         "records": records
            //     },
            // }
            //     callback(data)
            socket.emit("searchrelatedrecords", searchrelatedrecordsBody, (data: IResponse) => {
                if (callback && data.success == true) {
                    const sugarData = data.data.success.find((p: any) => p.platform == "sugarcrm")
                    if (sugarData) {
                        response = { status: sugarData.result.status, data: sugarData.result.data.records }
                    } else {
                        response = { status: 404, data: {} }
                    }
                    callback(response);
                }
            });
        }
    }
}

/**
 * Busqueda de ultimas 5 actividades en el crm
 */
 export async function searchlastactivities(contactCallData:IContactCallData, callback: (value: any) => void): Promise<void>
 {
    let response = {} as responsePlatform
    if(newAccessData && socket != null)
    {
        const sugarAccess = await resetToken(newAccessData)
        if (sugarAccess && contactCallData.idCrm != '')  
        {
            const platform = getPlatform(sugarAccess)
            const searchlastactivitiesBody: IRequest = {
                platform: platform,
                args: {
                    module: contactCallData.type,
                    recordId: contactCallData.idCrm,
                    relatedModules: [
                        {  
                            "module": "Calls",
                            "date": "",
                            "status": ""
                        },
                        {  
                            "module": "Tasks",
                            "date": "",
                            "status": ""
                        },
                        {  
                            "module": "Notes",
                            "date": "",
                            "status": ""
                        },
                        {  
                            "module": "Meetings",
                            "date": "",
                            "status": ""
                        },
                        {  
                            "module": "Cases",
                            "date": "",
                            "status": ""
                        },
                    ]
                }
            }
            //  // Solo para pruebas
            //  let records = CTLData
            //  let data = {
            //      success: true,
            //      data: {
            //          "records":records
            //      },
            //  }
            //      callback(data)

            socket.emit("searchlastactivities", searchlastactivitiesBody, (data: IResponse) => {
                if (callback && data.success == true) {
                    const sugarData = data.data.success.find((p: any) => p.platform == "sugarcrm");
                    if (sugarData) {
                        response = { status: sugarData.result.status, data: sugarData.result.data }
                    } else {
                        response = { status: 404, data: {} }
                    }
                    callback(response);
                }
            });
        }
    }
    else {
        response = { status: 404, data: {} };
        callback(response);
    }
}

 /**
 * Guardando llamada en crm
 */
export async function savecall(callData:ICall, recordId?: string): Promise<void>
{
    // const response = {} as responsePlatform;
    if(newAccessData && socket != null)
    {
        const sugarAccess = await resetToken(newAccessData);
        if (sugarAccess)
        {
            callData.assigned = newAccessData.userId
            const myData: any = {
                newData: callData,
                module: "Calls"
            }

            // Valor requerido para actualización
            if(recordId) myData.recordId = recordId;
            const platform = getPlatform(sugarAccess)
            const savecallBody: IRequest = {
                platform: platform,
                args: myData
            }
            // socket.emit("savecall", savecallBody, (data: IResponse) => {
            socket.emit("savecall", savecallBody)
                // if (callback && data.success == true) {
                //     const sugarData = data.data.success.find((p: any) => p.platform == "sugarcrm")
                //     if (sugarData) {
                //         response = { status: sugarData.result.status, data: sugarData.result.data }
                //     } else {
                //         response = { status: 404, data: {} }
                //     }
                //     callback(response);
                // }
            // });
        }
}
}


/**
 * Enviando peticion de relacionar registro a la llamada
*/
export async function sendlinkrecord(parentRecord: IParentRelatedRecord, childRecord: IChildRelatedRecord, rel_ids:any[], callback: (value: any) => void): Promise<void>
{
    let response = {} as responsePlatform
    if (newAccessData && socket != null)
    {
        const sugarAccess = await resetToken(newAccessData)
        if (sugarAccess) 
        {
            const platform = getPlatform(sugarAccess)
            const relaterecordBody = {
                user: platform.auth.username,
                platform: platform,
                args: {
                    module: parentRecord.module,
                    recordId: parentRecord.id,
                    newData: {
                        "rel_module": childRecord.module,
                        "link_name": childRecord.module.toLowerCase(),
                        "rel_ids": rel_ids
                    }
                }
            }

            // Solo para pruebas
            // let data = {
            //     success: true,
            //     data: {
            //         "id":parentRecord.id
            //     },
            // }
            // callback(data)

            socket.emit("relaterecord", relaterecordBody, (data: IResponse) => {
                if (callback && data.success == true) {
                    const sugarData = data.data.success.find((p: any) => p.platform == "sugarcrm");
                    if (sugarData) {
                        response = { status: sugarData.result.status, data: sugarData.result.data }
                    } else {
                        response = { status: 404, data: {} }
                    }
                    callback(response);
                }
            });
        }
    }
}

/**
 * Enviando peticion de crear  registro
*/
// export function saverecord(recordModule: string, callback: (value: any) => void): void
// {
//     console.log("saverecord", recordModule);
// }

// Notificación (server -> cliente)
export function setListeners(handlers: IListeners): void
{
    if (handlers.onSendcall)
        socket?.on("sendcall" as UhuuSocketEventTypes, (data: IResponse) => handlers.onSendcall(data));
    if (handlers.onRingingcall)
        socket?.on("ringingcall" as UhuuSocketEventTypes, (data: IResponse) => handlers.onRingingcall(data));
    if (handlers.onProvideranswercall)
        socket?.on("provideranswercall" as UhuuSocketEventTypes, (data: IResponse) => handlers.onProvideranswercall(data));
    if (handlers.onAnswercall)
        socket?.on("answercall" as UhuuSocketEventTypes, (data: IResponse) => handlers.onAnswercall(data));
    if (handlers.onFinishedcall)
        socket?.on("finishedcall" as UhuuSocketEventTypes, (data: IResponse) => handlers.onFinishedcall(data));
    if (handlers.onHangupcall)
        socket?.on("hangupcall" as UhuuSocketEventTypes, (data: IResponse) => handlers.onHangupcall(data));
    if (handlers.onRelateRecords)
        socket?.on("relaterecord" as UhuuSocketEventTypes, (data: IResponse) => handlers.onRelateRecords(data));
    if (handlers.onCloseCall)
        socket?.on("closecall" as UhuuSocketEventTypes, (data: IResponse) => handlers.onCloseCall(data));
    if (handlers.onSaveCall)
        socket?.on("savecall" as UhuuSocketEventTypes, (data: IResponse) => handlers.onSaveCall(data));
}

function getPlatform(sugarAccess: any){
    const platform = {
        platform: "sugarcrm",
        host: newAccessData.pathname ? newAccessData.baseUrl+newAccessData.pathname +  newAccessData.sugarApiVer :  newAccessData.baseUrl+ "/" +newAccessData.sugarApiVer,
        auth: {
            "token": sugarAccess.token,
            "refreshToken": sugarAccess.refreshToken, 
            "downloadToken": sugarAccess.downloadToken,
            "username": newAccessData.userName
        }
    } 
    return platform
}

const resetToken = (sugarAccess: IsugarAccess): Promise<boolean> => 
{
    return new Promise((resolve: any, reject: any) => 
    {
        const newAccess: IsugarAccess = 
        {
            token: sugarAccess.token,
            refreshToken: sugarAccess.refreshToken,
            downloadToken: sugarAccess.downloadToken,
        };
        try 
        {
            const identify =
            {
                who: "app-cti",
                action: "getRefreshToken",
                platform: "sugar"
            }
            window.parent.postMessage(identify, "*");
            window.addEventListener('message', (msg:IResultEventListener) => 
            {
                if (msg.data.from == "widgetIframeRefreshToken") 
                {
                    newAccess.token = msg.data.data.token;
                    newAccess.refreshToken = msg.data.data.refreshToken;
                    newAccess.downloadToken = msg.data.data.downloadToken;
                    resolve(newAccess)
                }
                else 
                {
                    console.log("No se recuperarón datos de token");
                    reject(newAccess);
                }
            }, false);
        }
        catch (error) {
            console.error("No se recuperarón datos de token, error: ", error);
            reject(newAccess);
        }
    });
}
