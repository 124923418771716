import { h } from 'preact';
import { TextField } from '@material-ui/core';
import { useState } from 'preact/hooks';
import { IExtInput } from './definition';
import useSharedStyles from '../../style/theme';


export default function ExtInput(props:IExtInput) : any
{
    const classes = useSharedStyles()
    const [extNumber, setExtNumber] = useState('')
    const handleOnChange = (e: React.ChangeEvent<any>) => {
        const rawValue: string = e.target.value.trim();
        props.handleOnChangeExt(rawValue)
        setExtNumber(rawValue)
    }
    const handleOnKey = (e: React.KeyboardEvent<any>) => {
      if( e.type == "keyup" && e.code == "Enter" )
      {
        props.dialClickHandler()
      }
    }
  return (
      <div>
            <TextField
            className={classes.textField}
            id={"extNumber"}
            label="Ext Number **"
            type="number"
            value={extNumber||''}
            fullWidth
            onChange={handleOnChange}
            onKeyUp={handleOnKey}
            style={{ width: '96%', marginLeft: '2%', marginTop: '1.3em' }}
            error={extNumber == '' ? true : false}
              InputLabelProps={{
                  shrink: true,
              }}
              variant="outlined"
            />
      </div>
  );
}