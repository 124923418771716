import { h } from 'preact'
import PhoneEnabledIcon from '@material-ui/icons/PhoneEnabled'
import { phoneItem } from './definition';
import { CardHeader, IconButton, Typography } from '@material-ui/core';
import { IRecordData } from '../RecordItem/definition';


export default function PhoneItem(props:phoneItem) : any
{
    // const [selectedphone, setSelectedPhone] = useState({} as IRecordData)
    // useEffect(()=>{
    //         if( Object.keys(selectedphone).length != 0 ){
    //             props.callBack(selectedphone)
    //         }
    // }, [selectedphone]);
    

    const handleContactsPhoneClick = (data:any, phoneSelected:string) => {
        const dataContactList: IRecordData = { name: data.name, phoneNumber: phoneSelected, type: data.type, idCrm: data.idCrm };
        props.callBack(dataContactList)
    }
    return(
        <CardHeader 
            style={{ padding: "5px" }}
            avatar={
                <IconButton edge="end" aria-label="iconBtn" onClick={() => handleContactsPhoneClick(props.contactData, props.phoneNumer)}>
                    <PhoneEnabledIcon id="PhoneEnabledIcon" color="primary" />
                </IconButton>
            }
            title={<Typography variant="body2">{props.label}</Typography>}
            subheader={<Typography variant="caption">{props.phoneNumer}</Typography>}
        />
    )
}
