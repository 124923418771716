import { h } from 'preact';
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import { Button } from '@material-ui/core';
import { IDialogProps } from './definition';

const ConfirmDialog = (props: IDialogProps) : any => {
    const handleClose = (value: boolean) => {
        if(value)
        {
            props.handleAccept(value);
        }
        else if(props.handleReject)
        {
            props.handleReject(value);
        }
        else if(value !== undefined)
        {
            props.handleAccept(value);
        }
        else
        {
            props.handleAccept(false);
        }
    }

    return (
        <Dialog
            open={true}
            keepMounted
        >
            <DialogContent>
                <DialogContentText>
                    {props.text}
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button onClick={() => handleClose(false)}>Disagree</Button>
                <Button onClick={() => handleClose(true)}>Agree</Button>
            </DialogActions>
        </Dialog>
    )
}

export default ConfirmDialog;
