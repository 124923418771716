import { h } from 'preact';
import Fab from '@material-ui/core/Fab';
// import CallEndIcon from '@material-ui/icons/CallEnd';
// import PhoneInTalkIcon from '@material-ui/icons/PhoneInTalk';
import Phone from '@material-ui/icons/Phone';
import CloseIcon from '@material-ui/icons/Close';
import { IBottomBarData } from './interfaces';
import useSharedStyles from '../../style/theme';

export default function BottomBar( props: IBottomBarData ) : any 
{
    const classes = useSharedStyles();
    if(!props.enableCallBtn){
        return (
            <div className={classes.BB_root} style={{ textAlign: 'center' }}>
                {
                    <Fab color="primary" disabled aria-label="Disabled" ><Phone/></Fab>
                }
            </div>
        );
    }
    
    return (
        <div className={classes.BB_root} style={{ textAlign: 'center' }}>
        {
            props.callInProgress ?  
                // (props.callStatus=="ringing" && !props.isOutbound && props.dialClickAnswerCall) ?
                // <div></div>
                //  <Fab style={{ backgroundColor: "#4caf50", color: "white" }} aria-label="answer" onClick={()=>props.dialClickAnswerCall()} >
                //      <PhoneInTalkIcon />
                //  </Fab>
                // :
                <Fab color="secondary" aria-label="Hangup" onClick={()=>props.callEndClickHandler()} >
                {/* Sustituir por <CloseIcon />  cuando este habilitado hangup en pbx */}
                    <CloseIcon /> 
                </Fab>
            :
                props.dialClickHandler && 
                <Fab color="primary" aria-label="Dialpad" onClick={()=>props.dialClickHandler()}>
                    <Phone/>
                </Fab>
        }
        </div>
    )
}