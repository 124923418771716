import { Card, CardActions, CardContent, Chip, Typography } from '@material-ui/core'
import { h } from 'preact'
import { useEffect, useState } from 'preact/hooks'
import useSharedStyles from '../../style/theme'
import { Aplatform, IavaliablePlatforms } from './definition'


export default function AvaliablePlatforms(props: IavaliablePlatforms) : any 
{
    const classes = useSharedStyles()
    const [avaliablePlatforms, setAvaliablePlatforms] = useState([] as Aplatform[]);


    useEffect(() => {
        if (Object.keys(props.avaliablePlatforms).length != 0) {
            setAvaliablePlatforms(props.avaliablePlatforms)
        } else {
            setAvaliablePlatforms([])
        }
    }, [props.avaliablePlatforms])

    if (Object.keys(props.avaliablePlatforms).length == 0) {
        return (
            <Card className={classes.rootPlatformInfo}>
                <CardContent className={classes.cardTitle}>
                    <Typography variant="h6" component="h2">
                        Plataformas Disponibles
                    </Typography>
                    <Typography variant="body2" component="p">
                        Conecta tus cuentas y desbloquea integraciones entre ellas.
                    </Typography>
                </CardContent>
                <CardActions className={classes.cardContent}>
                    <Typography id="nodata" style={{ margin: "auto" }} variant="h6" component="h2">
                        No existen plataformas disponibles
                    </Typography>
                </CardActions>
            </Card>
        )
    }

    return (
        <div>
            <Card className={classes.rootPlatformInfo}>
                <CardContent className={classes.cardTitle}>
                    <Typography variant="h6" component="h2">
                        Plataformas Disponibles
                    </Typography>
                    <Typography variant="body2" component="p">
                        Conecta tus cuentas y desbloquea integraciones entre ellas.
                    </Typography>
                </CardContent>
                <CardActions className={classes.cardContent}>
                    {avaliablePlatforms.map((platform: Aplatform) => {
                        return (
                            <div key={platform.id}>
                                {!platform.avaliableStatus ?
                                    <Chip disabled={!platform.avaliableStatus} label={platform.name} color={platform.name == "sugarcrm" ? "secondary":"primary"} onClick={() => props.callbackConectPlatform(platform.name)} />
                                    :
                                    <Chip label={platform.name} color={platform.name == "sugarcrm" ? "secondary":"primary"} onClick={() => props.callbackConectPlatform(platform.name)} />
                                }
                            </div>
                        )
                    })}
                </CardActions>
            </Card>
        </div>
    )
}