import { Aplatform } from "../../components/avaliablePlatforms/definition";
import { Cplatform } from "../../components/connectedPlatforms/definition";


export const aPlatforms : Aplatform[] = [
    { name: "sugarcrm", id: "1234567810", urlConexion: "https://merxbpqa.sugarondemand.com", avaliableStatus: false },
    { name: "hubspot", id: "1234567811", urlConexion: "https://app.hubspot.com", avaliableStatus: true },
]

export const cPlatforms : Cplatform[] = [
    { name: "sugarcrm", hash: "artgplatfSug01", user: "arturog", pass: "", domain: "merxbpqa.sugarondemand.com", secure: true, id: "1234567810", enableService: true },
    // {name:"hubspot", hash:"artgplatfHub01", user:"arturog", pass:"", domain:"app.hubspot.com", secure:true, id:"1234567811", enableService:true},
]
