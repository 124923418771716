import { h } from 'preact'
import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import LinkOffIcon from '@material-ui/icons/LinkOff';
import LinkIcon from '@material-ui/icons/Link';
import RecordItem from '../core/RecordItem/RecordItem'
import { sugarcrmModules } from '../../utils/types/sugarCrm.modules.types'
import { IRelatedListProps, IColorSchemas } from './definition'
import LoadingListItem from '../core/LoadingListItem/LoadingListItem'
import { ContactsDataDummy } from '../../utils/Data/contactsData'
import { IRecordData } from '../core/RecordItem/definition'
import useSharedStyles from '../../style/theme'
import { useEffect, useState } from 'preact/hooks'
import { Card } from '@material-ui/core';

export default function RelatedRecordsList(props: IRelatedListProps) : any
{
	const classes = useSharedStyles();
	// const [newlinkrecords, setNewLinkRecords] = useState([] as CrmRecordData[]);
	// const [linkRecord, setLinkRecord] = useState({} as IRecordData);
	const [relatedRecords, setRelatedRecords] = useState(props.records)
	// const [searchStatus, setSearchStatus] = useState(props.searchState)
	const avatarColors: IColorSchemas = {
		"Cs": "orange",
		"Ca": "blue",
		"Co": "pink",
		"Ac": "green",
		"No": "green",
		"Us": "aqua",
		"Ta": "purple",
		"Ld": "purple",
		"Me": "aqua",
		"Ll": "blue",
	}

	useEffect(()=>{
        setRelatedRecords(props.records)
    }, [props.records])
	// useEffect(() => {
	// 	if (Object.keys(linkRecord).length != 0) {  //Verificando que no este vacio el campo
	// 		const crmRecord = props.records.find((i: any) => i.idCrm == linkRecord.idCrm); //Buscar el registro como CRM
	// 		if (crmRecord != undefined) {   //Verificar que la busqueda no se undefined
	// 			if (Object.keys(newlinkrecords).length == 0) { //Verificar si el arreglo de los registros vinculados o desvinculados esta vacio
	// 				setNewLinkRecords([crmRecord])
	// 			}
	// 			else {
	// 				let existRecord = newlinkrecords.find((i: any) => i.idCrm == linkRecord.idCrm); //Buscar si el registro se encuentra en el arreglo
	// 				if (existRecord) {  // Actualizar en caso de que exista
	// 					existRecord = crmRecord
	// 					setNewLinkRecords(newlinkrecords)
	// 					// props.handleUnlinkClick(newlinkrecords)
	// 				} else {   // Agregar en caso de que no exista
	// 					setNewLinkRecords([
	// 						...newlinkrecords,
	// 						crmRecord
	// 					])
	// 				}
	// 			}
	// 		}
	// 	}
	// }, [linkRecord]);

	
	// useEffect(() => {
	// 	if (Object.keys(newlinkrecords).length != 0) {
	// 		props.handleUnlinkClick(newlinkrecords)
	// 	}
	// }, [newlinkrecords]);

	// Callback del Botón Vincular  
	const handlelinkSelect = (record: IRecordData) => () => {
		props.handleLinkCallback(record)
		// setLinkRecord(record)
		// changeLink(record)
	}

	// // vincular o desvincular registro
	// const changeLink = (record: IRecordData) => {
	// 	relatedRecords.map((value) => {
	// 		if (value.idCrm === record.idCrm) {
	// 			value.isRelated = !value.isRelated
	// 		}
	// 	})
	// }
	/*if (callInProgress) {
		return <div style={{visible:'none'}}></div>
	}*/
	if (props.searchState == 'loading') {
		return (
			<Card className={classes.rootDiv}>
			<List dense>
				{ContactsDataDummy && ContactsDataDummy.length > 0 &&
					<div className={classes.rootRelatedRecords}>
						{
							ContactsDataDummy.map(dummy => {
								return (
									<ListItem  key={dummy.idCrm}>
										<LoadingListItem />
									</ListItem>
								)
							})
						}
					</div>}
			</List>
			</Card>
		)
	}
	return (
		<Card className={classes.rootDiv}>
			{relatedRecords.length ?
				<List dense className={classes.rootRelatedRecords}>
					{relatedRecords.map((value) => {
						const avatarLbl = value.module == ("Cases" as sugarcrmModules) ? "Cs" : value.module.substr(0, 2);
						const avatarClass = avatarColors[avatarLbl];
						const recordData: IRecordData = { name: value.name, phoneNumber: value.phone != undefined ? value.phone : '', type: value.module, idCrm: value.idCrm };
						const iconButtton = value.isRelated ? <LinkOffIcon id="linked" color="primary" /> : <LinkIcon id="unlinked" color="primary" />
						return (
							<ListItem key={value.idCrm}>
								{value.isRelated ? 
								<RecordItem record={recordData} avatar={avatarClass} avatarLbl={avatarLbl} from='Related' content={iconButtton}/>
								:
								<RecordItem record={recordData} avatar={avatarClass} avatarLbl={avatarLbl} from='Related' content={iconButtton} callBack={handlelinkSelect} />
								}
							</ListItem>
						);
					})}
				</List>
				:
				<div className={classes.rootPaper}>
						<h5 className={classes.message}>No hay datos disponibles.</h5>
				</div>
			}
		</Card>
	);
}