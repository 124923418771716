import { h } from 'preact';
import 'react-phone-input-2/lib/material.css'
import { useEffect, useState } from 'preact/hooks';
import PhoneInput from 'react-phone-input-2'
import ContactList from '../../components/contactList'
import SearchInput from '../../components/searchInput'
import { IHomeScreenProps } from '../interfaces';
import { IContactListProps } from '../../components/contactList/definition';
import { IRecordData } from "../../components/core/RecordItem/definition";
import { Tab, Tabs } from '@material-ui/core';
import PhoneIcon from '@material-ui/icons/Phone';
import PhoneInTalkIcon from '@material-ui/icons/PhoneInTalk';
import { IExtInput } from '../../components/extInput/definition';
import ExtInput from '../../components/extInput';
import { INumberCodeCountry } from '@my-uhuu/cti_types';

export default function HomeScreen(props: IHomeScreenProps) : any
{   
    // TO BE FIXED - determinar si es necesaria la propiedad callInProgress para el componente
    const callInProgress = false
    const searchContact = props.searching
    const contactsD = props.contacts
    const [phoneNumber, setPhoneNumber] = useState("");
    const [contact, setContact] = useState<IRecordData|null>();
    const [phoneData, setPhoneData] = useState({} as INumberCodeCountry);
    const [callOption, setCallOption] = useState(0);
    const [extNumber, setExtNumber] = useState('');

    const validatePhoneNumber = ( value:string, country:any ):boolean => {
        const isValid = validatePhone(value, country)
        const data:INumberCodeCountry = phoneData
        data.isValid = isValid
        data.country = country
        data.phoneNumber = phoneNumber
        setPhoneData(data)
        props.validCallBtn(isValid)
        return isValid
    }
    
    const validatePhone = (phoneNumber:string, country:any):boolean => {
        const phoneLenght = country.format.match(/\./g).length
        if( phoneNumber.length == phoneLenght ){
            return true
        }
        else{
            return false
        }
    }

    const handleContactsPhoneClick = (data:any)  => {
        let phoneNumber = data.phoneNumber
        if( !phoneNumber.startsWith("+") ){
            phoneNumber = "+52".concat(phoneNumber);
        }
        setContact(data)
        setPhoneNumber( phoneNumber )
        props.validCallBtn(true)
        setTimeout(() => {
            props.callByContact(data, phoneData)
        }, 100);
    }
    
    const handleSearch = (data:string) => {
        props.handleSearchEvent(data)
    }

    const handleOnChangePhone = (value:string, country:any, e:any, formattedValue:any) => {
        if(validatePhone(value, country)){
            setPhoneNumber( formattedValue )
        }
        if(contact){
            setContact( null )
        }
    }
    
    useEffect(() => {
        if(phoneData.isValid){
            props.onValidPhone(phoneData)
        }
    }, [phoneData.isValid, phoneData.phoneNumber, phoneData.country]);

        
    useEffect(() => {
        if(extNumber != ''){
            props.handleExtCall(extNumber)
            props.validCallBtn(true)
        }else{
            props.validCallBtn(false)
        }
    }, [extNumber]);

    useEffect(() => {
        if(callOption == 0){
            props.handleCallOption("PHONE")
        }else{
            props.handleCallOption("EXT")
        }
    }, [callOption]);

    
    
    function a11yProps(index: any) {
        return {
            id: `simple-tab-${index}`,
            'aria-controls': `simple-tabpanel-${index}`,
        };
    }
    const handleChange = (event: React.ChangeEvent<any>, newValue: number) => {
        setCallOption(newValue);
    };
    const handleOnChangeExt = (extNum: string) => {
        setExtNumber(extNum)
    }
    const handleOnKey = (e: React.KeyboardEvent<any>) => {
        if( e.type == "keydown" && e.code == "Enter" )
        {
           props.dialClickHandler()
        }
    }

    const dataSearchInput = { lbl: "CRM Search", initialValue: props.searchDefaultValue||"", showIcon: true, disableSearch: searchContact, onSearch: handleSearch };
    const dataContactList: IContactListProps = { callInProgress: callInProgress, searchContact: searchContact, handlePhoneClick: handleContactsPhoneClick, contacts: contactsD };
    const dataPhoneInput = { 
        title: 'Phone Input', 
        country: "mx", 
        enableSearch: true, 
        specialLabel: "Type a phone number **", 
        inputProps: { readOnly: false }, 
        inputStyle: { width: '100%' }, 
        containerStyle: { width: '96%', marginLeft: '2%', marginTop: '1.3em' },
        isValid: validatePhoneNumber,
        value: phoneNumber,
        onChange: handleOnChangePhone,
        onKeyDown: handleOnKey
    };
    const extInputProps: IExtInput = { handleOnChangeExt: handleOnChangeExt, dialClickHandler: props.dialClickHandler }

    return (
        <div>
            <Tabs value={callOption} onChange={handleChange} variant="scrollable" indicatorColor="primary" textColor="primary" aria-label="icon label tabs example" style={{ width: '96%', marginLeft: '2%', borderBottom: "1px solid #ddd" }}>
                <Tab label="Phone"  icon={<PhoneIcon />} {...a11yProps(0)} />
                <Tab label="Ext"   icon={<PhoneInTalkIcon />} {...a11yProps(1)} />
            </Tabs>
            {callOption == 0 ?
            <PhoneInput {...dataPhoneInput} />
            :
            <ExtInput {...extInputProps} />
            }
            <SearchInput {...dataSearchInput} />
            <ContactList {...dataContactList} />
        </div>
    );
  }
