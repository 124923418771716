import { Avatar, Card, CardActions, CardContent, Chip, Divider, Fab, IconButton, List, ListItem, ListItemAvatar, ListItemText, Snackbar, Switch, TextField, Typography } from '@material-ui/core'
import { h } from 'preact'
import { useEffect, useState } from 'preact/hooks'
import useSharedStyles from '../../style/theme'
import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';
import { Cplatform, IconnectedPlatforms } from './definition'
import MuiAlert, { AlertProps } from '@material-ui/lab/Alert';

function Alert(props: AlertProps) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
}

export default function ConnectedPlatforms(props: IconnectedPlatforms) : any 
{
    const classes = useSharedStyles()
    const [connectedPlatforms, setConnectedPlatforms] = useState([] as Cplatform[]);
    const [enableEditURL, setEnableEditURL] = useState(false);
    const [messageSave, setMessage] = useState(false);
    const [sugarURL, setSugarURL] = useState(String);
    const [platformService, setPlatformService] = useState({
        sugarcrm: false,
        hubspot: false,
        sharpspring: false,
    });

    useEffect(() => {
        if (Object.keys(props.connectedPlatForms).length != 0) {
            setConnectedPlatforms(props.connectedPlatForms)
            props.connectedPlatForms.map((plataforma) => {
                switch (plataforma.name) {
                    case "sugarcrm":{
                        setPlatformService({
                            ...platformService,
                            [plataforma.name]: plataforma.enableService,
                        })
                        setSugarURL(plataforma.domain)
                    }
                        break;
                    default:{
                        setPlatformService({
                            ...platformService,
                            [plataforma.name]: plataforma.enableService,
                        })
                    }
                        break;
                }
                setPlatformService(platformService)
            }
            )
        } else {
            setConnectedPlatforms([])
        }
    }, [props.connectedPlatForms])
    

    //#TODO posiblemente eliminar useEffect platformService


    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        if (!event.target.checked) {
            const resp = confirm(`Estas seguro de deshabilitar la conexión con la plataforma ${event.target.name}?`);
            if (resp) {
                setPlatformService({ ...platformService, [event.target.name]: event.target.checked });
                props.callBackEnableService(event.target.id, event.target.checked)
            }
        } else {
            setPlatformService({ ...platformService, [event.target.name]: event.target.checked });
            props.callBackEnableService(event.target.id, event.target.checked)
        }
    };
    const handlesugarcrmURL = (event: React.ChangeEvent<HTMLInputElement>) => {
        setSugarURL(event.target.value)
    };

    const handleEditUrlSugar = () => {
        setEnableEditURL(!enableEditURL)
        if (enableEditURL) {
            const sugarData = props.connectedPlatForms.find((i: any) => i.name = "sugarcrm")
            if (sugarData != undefined) {
                setSugarURL(sugarData.domain)
            }
        }
    };
    const saveSugarUrl = () => {
        props.callBackChangeSugarUrl(sugarURL)
        setEnableEditURL(!enableEditURL)
        setMessage(true)
    };
    const closeMessage = () => {
        setMessage(false)
    }

    const handletDeletePlatform = (platformData: Cplatform) => {
        const resp = confirm(`Estas seguro de eliminar la conexión con la plataforma ${platformData.name}?`);
        if (resp) {
            props.callBackDeletedPlatform(platformData)
        }
    };

    if (Object.keys(props.connectedPlatForms).length == 0) {
        return (
            <Card className={classes.rootListPlatforms}>
                <CardContent className={classes.cardTitle}>
                    <Typography variant="h6" component="h2">
                        Plataformas Conectadas
                    </Typography>
                </CardContent>
                <CardActions >
                    <Typography id="nodata" style={{ margin: "auto" }} variant="h6" component="h2">
                        No hay plataformas Conectadas
                    </Typography>
                </CardActions>
            </Card>
        )
    }

    return (
            <Card className={classes.rootListPlatforms}>
                <CardContent className={classes.cardTitle}>
                    <Typography variant="h6" component="h2">
                        Plataformas Conectadas
                    </Typography>
                </CardContent>
                <List style={{ backgroundColor: "white" }}>
                    {/* <List style={{ background: "linear-gradient(lightgoldenrodyellow,#989898 )" }}> */}
                    {connectedPlatforms.map((value: Cplatform) => {
                        return (
                            <ListItem key={value.id} style={{ display: "block" }}>
                                {/* Título de la plataforma */}
                                <ListItem style={{ padding: "0px" }}>
                                    <ListItemText >
                                        <Typography variant="h6">{value.name}</Typography>
                                    </ListItemText>
                                    <Snackbar open={messageSave} autoHideDuration={6000} onClose={closeMessage}>
                                        <Alert onClose={closeMessage} severity="success">
                                            Success!
                                        </Alert>
                                    </Snackbar>

                                    {value.name == "sugarcrm" ?
                                        (
                                            !enableEditURL ?
                                                <span>
                                                    <IconButton id="EditSugarURL"onClick={handleEditUrlSugar}>
                                                        <EditIcon style={{ color: "daygray" }} />
                                                    </IconButton >
                                                    <Fab color="secondary" style={{ width: "40px", height: "40px" }} aria-label="delete" onClick={() => handletDeletePlatform(value)}>
                                                        <DeleteIcon />
                                                    </Fab>
                                                </span>
                                                :
                                                <span>
                                                    <Chip label="Cancel" color="primary" onClick={handleEditUrlSugar} />
                                                    <Chip label="Save" color="secondary" onClick={saveSugarUrl} />
                                                </span>
                                        ) :
                                        <span>
                                            <Fab color="secondary" style={{ width: "40px", height: "40px" }} aria-label="delete" onClick={() => handletDeletePlatform(value)}>
                                                <DeleteIcon />
                                            </Fab>
                                        </span>
                                    }
                                </ListItem>

                                {/* Contenido de la plataforma */}
                                <ListItem>
                                    <ListItemAvatar>
                                        <Avatar aria-label="plat" className={classes.avatar}>{value.name == "sugarcrm" ? "Su" : value.name == "hubspot" ? "Hu" : value.name == "sharpspring" ? "Sh" : "Plt"}</Avatar>
                                    </ListItemAvatar>
                                    <TextField
                                        label="URL"
                                        name={value.name}
                                        disabled={value.name == "sugarcrm" ? !enableEditURL : true}
                                        style={{ marginLeft: "5px" }}
                                        size="small"
                                        fullWidth
                                        onChange={handlesugarcrmURL}
                                        value={value.name == "sugarcrm" ? sugarURL : value.domain}
                                        className={classes.conectionPlatform}
                                    />
                                    <Switch
                                        checked={value.name == "sugarcrm" ? platformService.sugarcrm : value.name == "hubspot" ? platformService.hubspot : platformService.sharpspring}
                                        onChange={handleChange}
                                        id={value.id}
                                        name={value.name}
                                        inputProps={{ 'aria-label': 'checkbox' }}
                                    />
                                </ListItem>
                                <Divider />
                            </ListItem>
                        );
                    })}
                </List>
            </Card>
    )
}
