import { h } from "preact";
import IconButton from '@material-ui/core/IconButton';
import SearchIcon from '@material-ui/icons/Search';
import { useEffect, useState } from "preact/hooks";
import { REGEX } from "../../utils/constants";
import { IsearchProps }  from "./interfaces";
import useSharedStyles from '../../style/theme';
import { Divider, InputBase, Paper, Typography } from "@material-ui/core";

export default function SearchInput(props: IsearchProps) : any
{
    const classes = useSharedStyles();
    const [searchValue, setSearchValue] = useState(props.initialValue);
    const [helperText, setHelperText] = useState("");
    const [error, setError] = useState(false);

    useEffect(()=>{
        setSearchValue(props.initialValue)
    }, [props.initialValue])

    const validateSearchValue = (rawValue:string) => {
        const regexp = new RegExp(REGEX.search, "i");
        const regexValue = regexp.exec(rawValue) as Array<any>;
        if( (regexValue !== null && (regexValue[1] !== undefined || regexValue[4] !== undefined) ) && rawValue.length > 2)
        {
           setError(false);
           setHelperText("");
        }
        else if(rawValue.length == 0)
        {
            setError(false);
            setHelperText("");
        }
        else
        {
            setError(true);
            setHelperText("Invalid: only letters and at least 3 characters");
        }
        setSearchValue(rawValue);
    }

    // validando el initial value
    if( props.initialValue.length && ( props.initialValue == searchValue && !error ) ){        
        const rawValue: string = props.initialValue;
        validateSearchValue(rawValue)
    }

    const handleOnSearch = (e: React.KeyboardEvent<any>) => {
        if( e.type == "keyup" && e.code == "Enter" && !error && searchValue.length )
        {
            props.onSearch(searchValue);
        }
    }

    const handleOnChange = (e: React.ChangeEvent<any>) => {
        const rawValue: string = e.target.value.trim();
        validateSearchValue(rawValue)
    }

    return (
        <div className={classes.SI_searchContainer}>
            <Paper className={classes.rootSearch} style = { error ? { border: "1px solid red" }:{ border: "0px" }  }>
                 {/* <AccountCircleIcon/> */}
                <InputBase
                    className={classes.input}
                    id="standard-search" 
                    placeholder={props.lbl||"Search"} 
                    // type="search"
                    value={searchValue||''}
                    error={error}
                    inputProps={{ 'aria-label': 'search' }}
                    onChange={handleOnChange}
                    onKeyUp={handleOnSearch}
                />
                <Divider className={classes.divider} orientation="vertical" />
                <IconButton  aria-label="search" disabled={ props.disableSearch || error || searchValue.length==0 } onClick={()=>props.onSearch(searchValue)}>
                    <SearchIcon />
                </IconButton>
            </Paper>
            {error &&
                 <Typography variant="caption" className={classes.rootSearch} style = {{ color: "red" }}>
                     {helperText}
                 </Typography>
            }
        </div>
    )
}
