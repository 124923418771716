import { h } from 'preact';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import BugReportIcon from '@material-ui/icons/BugReport';
import InsertInvitationIcon from '@material-ui/icons/InsertInvitation';
import NoteIcon from '@material-ui/icons/Note';
import AssignmentTurnedInIcon from '@material-ui/icons/AssignmentTurnedIn';
import CallIcon from '@material-ui/icons/Call';
import Link from '@material-ui/core/Link';
import useSharedStyles from '../../style/theme';
import { ICTLIconsObject, IContactTimeLineProps, TabPanelProps } from './definition';
import { Avatar, Box, Card, CardContent, CardHeader, Collapse, IconButton, ListItemText, Tab, Tabs } from '@material-ui/core';
import { sugarcrmModules } from '../../utils/types/sugarCrm.modules.types';
import { useState } from 'preact/hooks';
import LoadingTabsItem from '../core/LoadingTabsItem/LoadingTabsItem';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import clsx from 'clsx';

const icons: ICTLIconsObject = {
    "cases": <BugReportIcon />,
    "meetings": <InsertInvitationIcon />,
    "notes": <NoteIcon />,
    "tasks": <AssignmentTurnedInIcon />,
    "calls": <CallIcon />,
}
function TabPanel(props: TabPanelProps) {
    const { children, value, index, ...other } = props;
    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`scrollable-auto-tabpanel-${index}`}
            aria-labelledby={`scrollable-auto-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box p={3} style={{ padding: "10px" }}>
                    <Typography>{children}</Typography>
                </Box>
            )}
        </div>
    );
}
function a11yProps(index: any) {
    return {
        id: `scrollable-auto-tab-${index}`,
        'aria-controls': `scrollable-auto-tabpanel-${index}`,
    };
}

export default function ContactTimeLine(props: IContactTimeLineProps) : any
{
    const [value, setValue] = useState(0);
    const [expanded, setExpanded] = useState(false);
    const handleExpandClick = () => {
        setExpanded(!expanded);
    };

    const handleChange = (event: React.ChangeEvent<any>, newValue: number) => {
        setValue(newValue);
    };

    const classes = useSharedStyles();
    if (props.records.length == 0 && !props.isLoading) {
        return (
            <Paper elevation={3} className={classes.CTL_container} style={{ height: "100px" }}>
                <Typography align="center" variant="body1" component="h1">
                    No data
                </Typography>
            </Paper>
        )
    }
    return (
        <Card className={classes.CTL_container}  >
            {props.isLoading ?
                <LoadingTabsItem />
                :
                <Paper square style={{ backgroundColor: "#f7f8f9" }}>
                    <Tabs value={value} onChange={handleChange} variant="scrollable" scrollButtons="on" indicatorColor="primary" textColor="primary" aria-label="scrollable auto tabs example" style={{ borderBottom: "1px solid #ddd", backgroundColor: "white" }}>
                        {props.records.map((record, i) => {
                            return (<Tab
                                key={record.idCrm}
                                icon={
                                    icons[record.module.toLowerCase()]
                                } {...a11yProps(i)}
                            // style={{ color: record.module == "Cases" ? "coral" : record.module == "Meetings" ? "lightseagreen" : record.module == "Notes" ? "mediumseagreen" : record.module == "Tasks" ? "mediumpurple" : record.module == "Calls" ? "dodgerblue" : classes.finishedColor }} 
                            />
                            )
                        }
                        )}
                    </Tabs>
                    {props.records.map((record, i) => {
                        const avatarLbl = record.module == ("Cases" as sugarcrmModules) ? "Cs" : record.module == ("Leads" as sugarcrmModules) ? "Ld" : record.module == ("Calls" as sugarcrmModules) ? "Cl" : record.module.substr(0, 2);
                        const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
                        return (
                            <TabPanel key={record.idCrm} value={value} index={i}>
                                <Card >
                                    <CardHeader
                                        style={{ padding: "6px" }}
                                        avatar={
                                            <Avatar className={avatarLbl == 'Co' ? classes.pink : avatarLbl == 'Ld' || avatarLbl == 'Ta' ? classes.purple : avatarLbl == 'Cs' ? classes.orange : avatarLbl == 'No' || avatarLbl == 'Ac' ? classes.green : avatarLbl == 'Me' || avatarLbl == 'Us' ? classes.aqua : avatarLbl == 'Ll' || avatarLbl == 'Cl' ? classes.blue : classes.defaultAvatarColor} >
                                                {avatarLbl}
                                            </Avatar>
                                        }
                                        title="Nombre"
                                        subheader={<Link color="primary" variant="body2" onClick={() => props.handleClick(record)} >{record.name}</Link>}
                                        action={                                       
                                             <IconButton
                                            className={clsx(classes.expand, {
                                                [classes.expandOpen]: expanded,
                                            })}
                                            onClick={handleExpandClick}
                                            aria-expanded={expanded}
                                            aria-label="show more"
                                            >
                                            <ExpandMoreIcon />
                                            </IconButton>
                                        }
                                    />
                                    <Collapse in={expanded} timeout="auto" unmountOnExit>
                                    <CardContent style={{ padding: "6px", display: "flex" }}>
                                            <ListItemText
                                                primary= {<Typography variant="body2" color="textSecondary" component="p">Descripción</Typography> }
                                                secondary={
                                                    <Typography
                                                    component="span"
                                                    variant="body2"
                                                    color="textPrimary"
                                                    >
                                                    {record.description}
                                                    </Typography>
                                                }
                                            />
                                    </CardContent>
                                    <CardContent style={{ padding: "6px", display: "flex" }}>
                                        {record.date &&
                                            <ListItemText
                                                primary= {<Typography variant="body2" color="textSecondary" component="p">Creado</Typography> }
                                                secondary={
                                                    <Typography
                                                    component="span"
                                                    variant="body2"
                                                    color="textPrimary"
                                                    >
                                                    {/* {record.date} */}
                                                    {`${new Date(record.date).toISOString().substring(0, 10)}  ${new Date(record.date).toLocaleTimeString('en', { hour12: true, timeZone: timezone })}`}
                                                    </Typography>
                                                }
                                            />
                                        }
                                    </CardContent>
                                    </Collapse>
                                </Card>
                            </TabPanel>
                        )
                    }
                    )}
                </Paper>
            }
        </Card>
    );
}