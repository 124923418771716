import { h } from 'preact'
import { Grid, IconButton, Typography } from '@material-ui/core';
import CancelIcon from '@material-ui/icons/Cancel';
import useSharedStyles from '../../style/theme';
import { IconfigScreen } from './definition';
import { useEffect, useState } from 'preact/hooks';
import ConnectedPlatforms from '../../components/connectedPlatforms';
import { Cplatform, IconnectedPlatforms } from '../../components/connectedPlatforms/definition';
import { cPlatforms, aPlatforms } from '../../utils/Data/platformsData';
import { IavaliablePlatforms } from '../../components/avaliablePlatforms/definition';
import AvaliablePlatforms from '../../components/avaliablePlatforms';

export default function ConfigScreen(props: IconfigScreen) : any
{
  const classes = useSharedStyles();
  // const [showlogin, setShowLogin] = useState(false);
  const [relatedPlatforms, setEditPlatforms] = useState(cPlatforms as Cplatform[]);
  const [avaliablePlatforms, setAvaliablePlatforms] = useState(aPlatforms);
  const [selectedPlatform, setSelectedPlatform] = useState({} as Cplatform);
  useEffect(() => {
    if(Object.keys(selectedPlatform).length != 0){
      const platforms =[...relatedPlatforms]
      const exist = platforms.find((i: any) => i.id == selectedPlatform.id)
        if (exist == undefined) {
          platforms.push(selectedPlatform)
          setEditPlatforms(platforms)
        }
    }
  }, [selectedPlatform])

  const callBackDeletedPlatform = (platformRemoved: Cplatform) => {
    const pltaforms = relatedPlatforms.filter((i: any) => i.id != platformRemoved.id)
    setEditPlatforms(pltaforms)
  };

  const callBackChangeSugarUrl = (url: string) => {
    const sugarPlatform = relatedPlatforms.find((i:any) => i.name == "sugarcrm")
    if(sugarPlatform != undefined){
      sugarPlatform.domain = url
    }
  };
  const callBackChangeConexionPlatform = (idPlatformChange: string, valueService: boolean) => {
    const plataforma = relatedPlatforms.find((i:any) => i.id == idPlatformChange)
    if(plataforma != undefined){
      plataforma.enableService = valueService
    }
  };


  const handleConectPlatform = (platform: string) => {
    switch (platform) {
      case "hubspot":{
        const resp = confirm("You are about to leave this page to login on hubspot. Do you want to continue?");
        if(resp)
        {
            const redirect = window.location.origin;
            const url = "https://app.hubspot.com/oauth/authorize?client_id=" + "3D78f57281-53c5-43ee-b3bd-a2cbfb7d29c4" + "&scope=contacts%20social&redirect_uri=" + redirect + "/auth";
            window.open(url, "hubspotauth", "fullscreen=no");
            const hubspot:Cplatform = { name: "hubspot", hash: "artgplatfHub01", user: "arturog", pass: "", domain: "app.hubspot.com", secure: true, id: "1234567811", enableService: true }
            setSelectedPlatform(hubspot)
        }
      }
        break;
      case "sugarcrm":{
          // setShowLogin(true)
          const platforms = avaliablePlatforms
          const relatePlatform = platforms.find((i:any) => i.name == 'sugarcrm');
          if(relatePlatform){
            relatePlatform.avaliableStatus=false
            setAvaliablePlatforms(platforms)
          }
        }
        break;
      default:
        break;
    }
  };

  const avaliablePlatformsProps:IavaliablePlatforms ={ callbackConectPlatform: handleConectPlatform, avaliablePlatforms: avaliablePlatforms }
  const conectedPlatformsProps:IconnectedPlatforms ={ callBackDeletedPlatform: callBackDeletedPlatform, callBackEnableService: callBackChangeConexionPlatform, callBackChangeSugarUrl: callBackChangeSugarUrl, connectedPlatForms: relatedPlatforms }
  return (
    <div className={classes.rootConfig}>
      <Grid  >
        <Grid item xs = {12} style={{ textAlign: "end" } }>
          <IconButton id="CloseConfig" aria-label="cancel" onClick={props.handleDrawerClose}>
            <CancelIcon  />
          </IconButton>
        </Grid>
      </Grid>

      <Typography  className={classes.rootConfig} gutterBottom variant="h6">
        Configuración de Conexiones
      </Typography>
       <AvaliablePlatforms {...avaliablePlatformsProps}/>
       {/* {showlogin && <div style={{display:"flex"}}> <ProgressLogin/></div>} */}
      <ConnectedPlatforms {...conectedPlatformsProps}/>

    </div>
  )
}