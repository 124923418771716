import { h } from 'preact';
import { useEffect, useState } from 'preact/hooks';
import CtiAppBar from '../../components/appBar'
import BottomBar from '../../components/bottomBar'
import HomeScreen from '../homeScreen'
import CallScreen from '../callScreen'
import { IAppProps, ICallInProgressData, ICallScreenProps, IChildRelatedRecord, IHomeScreenProps, INoteCall, IParentRelatedRecord, IsavedCall, alertStatus } from '../interfaces';
import { IAppBarData } from '../../components/appBar/interfaces';
import packageJson from '../../../package.json'
import { IRelatedActivitiesData } from '../../components/contactTimeLine/definition';
import { IRecordData } from '../../components/core/RecordItem/definition';
import { IContactCallData } from '../../components/callBar/definition';
import { CrmRecordData, statusSearch } from '../../components/relatedRecordsList/definition';
import { callStatusType, viewList } from '../../utils/types/lists.types';
import ClosedBar from '../../components/closedBar';
import { IconfigScreen } from '../configScreen/definition';
import ConfigScreen from '../configScreen';
import { IContactData } from '../../components/contactList/definition';
import ConfirmDialog from '../../components/confirmDialog';
import { IListeners } from '../../controllers/communication.interface';
import { ICall, INumberCodeCountry, IResponse } from '@my-uhuu/cti_types';
import { answercall, hangupcall, savecall, searchcontact, searchlastactivities, sendcall, sendlinkrecord, setListeners } from '../../utils/socket.io';
import { responsePlatform } from '../../utils/types/platformSugarResponse';
import { Snackbar } from '@material-ui/core';
import Alert from '@material-ui/lab/Alert';
import { iframePlatformData } from '../../utils/types/sugarCrm.modules.types';

export default function App(props: IAppProps) : any {
        // General App
    const [configOpen, setConfigOpen] = useState(false);
    const [userAccessData, setUserAccessData] = useState({} as iframePlatformData);
    const [openAlert, setOpenAlert] =useState(false);
    const vertical  = "top";
    const horizontal = "center";
    const [alertStatus, setAlertStatus] =useState("info" as alertStatus);
    const [alertMessage, setAlertMessage] =useState(String);
    const [actualView, setActualView] = useState("Home" as viewList);
    const [callSaveId, setCallSaveId] = useState({} as IsavedCall);
    const [timeTocall, setTimeToCall] = useState({
        start: "",
        recordStart: "",
        end: "",
        recordEnd: "",
        duration: ""
    });
    const [callInProgress, setCallInProgress] = useState(false); // Bandera para saver si existe una llamada en progreso
    const [isClic2Call, setIsClic2Call] = useState(false);
    const [callInProgressData, setcallInProgressData] = useState({} as ICallInProgressData) // Estado con los datos de la otra linea
    const [callContactData, setCallContactData] = useState({} as IContactCallData); // Estado con los datos del contacto de la otra linea y proveer a callScreen
    const [callStatus, setCallStatus] = useState('standby' as callStatusType); //Use in CallScreen and CtiAppBar
    const [inIframe, setInIframe] = useState(false); //Use in CallScreen and CtiAppBar
    const [validNumber, setValidNumber] = useState(Object); // Callback from HomeScreen, use for send to BackEnd and use in CallScreen
    // estados al detonar eventos al backend
    const [responseAnswerdCall, setResponseAnswerdCall] = useState({} as IResponse);  // response al contestar llamada
    const [responseHangupCall, setResponseHangupCall] = useState({} as IResponse);  // response al colgar llamada
    const [responseSendCall, setResponseSendCall] = useState({} as IResponse);  // response al comenzar llamada
    const [responseSaveCall, setResponseSaveCall] = useState({} as IResponse);  // response al  guardar llamada
    const [responseSearchContact, setResponseSearchContact] = useState({} as responsePlatform);  // response al buscar contacto
    const [responseSearchRelatedRecords, setResponseSearchRelatedRecords] = useState({} as responsePlatform);  // response al buscar registros relacionados a la llamada en el crm
    const [responseLastActivities, setResponseLastActivities] = useState({} as IResponse);  // response al buscar ultimas actividades en el crm
    // const [responseSaveRecord, setResponseSaveRecord] = useState({} as IResponse);  // response al crear registo en el crm
    const [responseSaveLinkRecord, setResponseSaveLinkRecord] = useState({} as IResponse);  // response al relacionar registro a la llamada en el crm
    const [responseRelateRecord, setResponseRelateRecord] = useState({} as IResponse);  
    const [responseRelateRecordCalltoContact, setResponseRelateRecordCalltoContact] = useState({} as IResponse);
    // const [linkedRecord, setLinkedRecord] = useState({
    //     id: '',
    //     module: ''
    // } as IChildRelatedRecord );  
    // const [responseRelateRecordtoContact, setResponseRelateRecordtoContact] = useState({} as IResponse);
    // const [unlinkRecord, setUnlinkRecords] = useState({} as IRecordData);  
    // const [activitieRelated, setActivitieRelated] = useState(Object); 
    // estados al recibir eventos desde el backend
    const [cathRingingCall, setCathRingingCall] = useState({} as IResponse);
    const [catchUpCall, setCatchUpCall] = useState({} as IResponse);
    const [cathFinishedcall, setcathFinishedcall] = useState({} as IResponse);

    //For AppBar props
    const [userName, setUserName] = useState(String);
    const [userExt, setUserExt] = useState(String);
    const [userOnline, setUserOnline] = useState(false);
    const appVersion = packageJson.version;

    // For BottomBar
    const [isValidNumber, setIsValidNumber] = useState(false);

    // For HomeScreen props
    const [searchActive, setSearchActive] = useState(false);
    const [listContacts, setListContacts] = useState([] as IContactData[]);
    const [callSelectOption, setCallSelectOption] = useState('');
    const [callExt, setCallExt] = useState('');
    // const [callByContactList, setCallByContactList] = useState(false);

    //For CallScreen props
    const [callIsOutbound, setCallIsOutbound] = useState(false)
    const [relatedStatus, setRelatedStatus] = useState('' as statusSearch);
    const [ctlStatus, setCtlStatus] = useState('' as statusSearch);
    const [contactIsRelated, setContactIsRelated] = useState(false);
    const [timelineRecords, setTimeRecords] = useState([] as IRelatedActivitiesData[]);
    const [relatedRecords, setRelatedRecords] = useState([] as CrmRecordData[]);
    const [noteData, setNoteData] = useState({
        noteDescription: '',
        noteTitle: ''
    } as INoteCall) 

    const [displayConfirmDialogFlag, setDisplayConfirmDialogFlag] = useState<boolean>(false);
    const [confirmDialogContainer, setConfirmDialogContainer] = useState<any>(null);

    useEffect(() => {
        if (props.loginPbxStatus !== null && props.loginPbxStatus != undefined) {
            setUserName(props.loginPbxStatus.data.extension);
            setUserExt(props.loginPbxStatus.data.extension);
            setUserOnline(true)
            console.log("El usuario se ha logueado", props.loginPbxStatus);
            if(props.loginPbxStatus.data.click_2_call == "Si"){
                setIsClic2Call(true)
            }
            // Set listeners
            const functions: IListeners = {
                onSendcall(data: IResponse) {
                    // Llamada Saliente
                    // console.log("Realizando llamada: ", data)
                    setCathRingingCall(data)
                },
                onRingingcall(data: IResponse) {
                    //Llamada Entrante
                    // console.log("Llamada entrante : ", data)
                    if(actualView != "Call"){
                        setCathRingingCall(data)
                    }
                },
                onProvideranswercall(data: IResponse) {
                    // Contestaron Llamada
                    // console.log("Contestaron Llamada : ", data)
                    if(actualView != "Call"){
                        setCatchUpCall(data)
                    }
                },
                onAnswercall(data: IResponse) {
                    // conteste llamada
                    // console.log("Conteste llamada: ", data)
                    if(actualView != "Call"){
                        setCatchUpCall(data)
                    }
                },
                onFinishedcall(data: IResponse) {
                    // Colgaron Llamada
                    // console.log("Colgaron Llamada : ", data)
                     setcathFinishedcall(data)
                },
                onHangupcall(data: IResponse) {
                    // Colgando  Llamada
                    // console.log("Colgue llamada ", data)
                        setcathFinishedcall(data)
                },
                onRelateRecords(data: IResponse) { 
                    // console.log("onRelateRecords: ", data)
                    let response = {} as responsePlatform
                    if (data.success == true) {
                        const sugarData = data.data.success.find((p: any) => p.platform == "sugarcrm");
                        if (sugarData) {
                            response = { status: sugarData.result.status, data: sugarData.result.data }
                                setResponseSaveLinkRecord(response)
                        }
                    }
                },
                onSocketdisconnected(data: IResponse) { 
                    console.log("onSocketdisconnected: ", data)
                },           
                onCloseCall(data: IResponse) { 
                    // console.log("closeCall: ", data)
                    if (data.success == true) {
                        setResponseHangupCall(data);
                    }
                },
                onSaveCall(data: IResponse) { 
                    let response = {} as responsePlatform
                    if (data.success == true) {
                        const sugarData = data.data.success.find((p: any) => p.platform == "sugarcrm")
                        if (sugarData) {
                            response = { status: sugarData.result.status, data: sugarData.result.data }
                            setResponseSaveCall(response)
                        }
                    }
                },

             
            }
            setListeners(functions);
        }
    }, [props.loginPbxStatus]);

    useEffect(() => {
        if(props.platformData){
            setInIframe(true)
            setUserAccessData(props.platformData)
        }
    }, [props.platformData]);


    useEffect(() => {
        if (Object.keys(catchUpCall).length != 0) {
            if (catchUpCall.success) {
                if (catchUpCall.data.direction == "outbound"){
                    setcallInProgressData({ 
                        status: "ringing",
                        cid: catchUpCall.data.cid,
                        direction: catchUpCall.data.direction,
                        pid: catchUpCall.data.pid
                    })
                 }else{
                     setcallInProgressData(catchUpCall.data)
                }
            }
        }
        if (Object.keys(cathRingingCall).length != 0) {
            if (cathRingingCall.data != undefined) {
                    setcallInProgressData(cathRingingCall.data)
            }
        }
    }, [catchUpCall, cathRingingCall])

    useEffect(() => {
        getcathFinishedcall(cathFinishedcall)
    }, [cathFinishedcall])

    const getcathFinishedcall = async(cathFinishedcallData:IResponse) => {
        if (Object.keys(cathFinishedcallData).length != 0) {
            if (cathFinishedcallData.success) {
                setTimeToCall({
                    ...timeTocall,
                    end: (new Date()).toISOString()
                })
                // const page = await theTabIsHidden()
                if (cathFinishedcallData.data.direction == "outbound"){
                    // if(page == false){
                        setcallInProgressData({ ...callInProgressData,
                            status: cathFinishedcallData.data.status,
                            cid: cathFinishedcallData.data.cid,
                            direction: "inbound"
                        })
                    // }else{
                    //     if(callInProgressData.pid){
                    //         cathFinishedcallData.data.pid = callInProgressData.pid
                    //     }                         
                    //     setcallInProgressData(cathFinishedcallData.data)
                    // }
                }else{ 
                    // if(page == false){
                        if(callInProgressData.pid){
                            cathFinishedcallData.data.pid = callInProgressData.pid
                        } 
                    setcallInProgressData(cathFinishedcallData.data)
                    // }else{
                    //     cleanStates()
                    // }
                }
            }
        }
    }

    useEffect(() => {
        if (Object.keys(responseSendCall).length != 0) {
            if (responseSendCall.success) {
                // console.log("Detonando llamada por clic2Call")
            }
        }
    }, [responseSendCall])

    useEffect(() => {
        if (Object.keys(responseAnswerdCall).length != 0) {
            if (responseAnswerdCall.success) {
                // console.log("Contestando llamada por clic2Call")
            }
        }
    }, [responseAnswerdCall])

    useEffect(() => {
        if (Object.keys(responseHangupCall).length != 0) {
            if (responseHangupCall.success) {
                setcallInProgressData({ ...callInProgressData,
                    direction: "outbound",
                    status: "hangup"
                })
            }
        }
    }, [responseHangupCall])

    //cambio estado de listContacts
    useEffect(()=>{
        if (responseSearchContact != undefined) {
            setSearchActive(false)
            if (responseSearchContact.status == 200) {
                const records = [] as IContactData[]
                responseSearchContact.data.map((value: any) => {
                    const record: IContactData = {
                        name: value.name,
                        phoneMobile: value.phone_mobile ? value.phone_mobile : "",
                        phoneWork: value.phone_work ? value.phone_work : "",
                        phoneHome: value.phone_home ? value.phone_home : "",
                        type: value._module,
                        idCrm: value.id
                    }
                    records.push(record)
                })
                setListContacts(records)
            } else {
                setSearchActive(false)
            }
        }
    }, [responseSearchContact])

    // cambio estado de la busqueda de relatedRecords
    useEffect(() => {
        if (responseSearchRelatedRecords != undefined) {
            if (responseSearchRelatedRecords.status == 200) {
                setRelatedStatus("success") 
                const records = [] as CrmRecordData[]  
                responseSearchRelatedRecords.data.map((value: any) => {
                    const record: CrmRecordData = {
                        name: value.name,
                        description: value.description,
                        module: value._module,
                        idCrm: value.id,
                        isRelated: value.id == callContactData.idCrm || value.id == callSaveId.id ? true : false
                    }
                    if(value.phone_mobile){
                        record.phone = value.phone_mobile
                    }
                    records.push(record)
                })
                setRelatedRecords(records)
            } else {
                setRelatedStatus("success")
            }
        }
    }, [responseSearchRelatedRecords])


    // cambio estado al mandar a guardar la llamada
    useEffect(() => {
        if (responseSaveCall != undefined) {
            if (responseSaveCall.status == 200) {
                const saveCall :IsavedCall ={
                    id: responseSaveCall.data.id,
                    name: responseSaveCall.data.name,
                    dateCreate: responseSaveCall.data.date_entered,
                    description: responseSaveCall.data.description,
                    durationHours: responseSaveCall.data.duration_hours,
                    durationMinutes: responseSaveCall.data.duration_minutes,
                    dateStart: responseSaveCall.data.date_start,
                    dateEnd: responseSaveCall.data.date_end,
                    module: responseSaveCall.data._module,
                }
                if (callInProgress) {
                    if (Object.keys(callSaveId).length != 0  && callSaveId.id != undefined) {
                        if(responseSaveCall.data.status != "Held"){
                            setCallSaveId({
                                ...callSaveId,
                                name: saveCall.name,
                                description: saveCall.description,
                            })
                            if (callContactData.idCrm != '') {
                                if(callSaveId.name != saveCall.name || callSaveId.description != saveCall.description){
                                    setCtlStatus("loading")
                                    searchlastactivities(callContactData, setResponseLastActivities)
                                }
                            }
                        }
                    }else{
                        if (callContactData.idCrm) {
                            linkRecordData(callContactData.idCrm, callContactData.type, saveCall.id, saveCall.module, setResponseRelateRecordCalltoContact)
                        }
                        setCallSaveId(saveCall)
                        setTimeToCall({ ...timeTocall,
                            recordStart: saveCall.dateStart ? saveCall.dateStart : timeTocall.start,
                            recordEnd: saveCall.dateEnd ? saveCall.dateEnd : timeTocall.end,
                        });
                    }
                }else{
                    if(responseSaveCall.data.status == "Held"){
                        saveClosedCallScreen(responseSaveCall)
                    }
                }
            }
        }
    }, [responseSaveCall])


    // cambio estado al relacionar registro
    useEffect(() => {
        if (responseRelateRecord.status == 200) {
            // if (callContactData.idCrm != '') { 
            if (Object.keys(callContactData).length != 0 && callContactData.idCrm) {
                setCtlStatus("loading")
                searchlastactivities(callContactData, setResponseLastActivities)
            }
        }
    }, [responseRelateRecord])
        // cambio estado al relacionar registro

        useEffect(() => {
            if (responseRelateRecord.status == 200) {
                openAlertMessage("info", "The call has been related to the contact")
            }
        }, [responseRelateRecordCalltoContact])



        // cambio estado de la busqueda de ultimas actividades
        useEffect(() => {
            if (responseLastActivities != undefined) {
                setSearchActive(false)
                if (responseLastActivities.status == 200) {
                    setCtlStatus("success") 
                    const records = [] as IRelatedActivitiesData[]  
                    responseLastActivities.data.map((value: any) => {
                        const record: IRelatedActivitiesData = {
                            name: value.name,
                            description: value.description,
                            module: value.module,
                            idCrm: value.recordId,
                            date: value.date ? value.date : ""
                        }
                        records.push(record)
                    })
                    setTimeRecords(records)
                } else {
                    setCtlStatus("success")
                }
            }
        }, [responseLastActivities])
    

    
    useEffect(() => {
        if (responseSaveLinkRecord.status==200) {
            if(responseSaveLinkRecord.data.record._module == "Contacts"){
                openAlertMessage('success', "The record was successfully linked to the Contact")
                if( callContactData.idCrm == ''){                      
                    const contactData: IContactCallData =
                    {
                        name: responseSaveLinkRecord.data.record.name,
                        phoneNumber: callContactData.phoneNumber,
                        type: responseSaveLinkRecord.data.record._module,
                        idCrm: responseSaveLinkRecord.data.record.id
                    }
                    setCallSaveId({
                        ...callSaveId,
                        name: `Llamada con ${contactData.name}`,
                    })
                    setCallContactData(contactData)
                    setContactIsRelated(true)
                    setTimeout(() => {
                        setCtlStatus("loading")
                        searchlastactivities(contactData, setResponseLastActivities)
                    }, 1000);
                }         
                // if(callSaveId.id != '' && linkedRecord.id != ''){
                //     linkRecordData(callSaveId.id, callSaveId.module, linkedRecord.id, linkedRecord.module, setResponseSaveLinkRecord)
                // }
            }else{
                openAlertMessage("success", "The record was successfully linked to the call")
            }
        }
        if(responseSaveLinkRecord.status==404)   
        {
            if(responseSaveLinkRecord.data.error_message){
                openAlertMessage("error", responseSaveLinkRecord.data.error_message)
            }else{
                openAlertMessage("error", "Could not link the record")
            }
        }
    }, [responseSaveLinkRecord])

    useEffect(()=>{
        if(Object.keys(callInProgressData).length != 0){
            // console.log("La llamada es::: ",callInProgressData)
            let newCallStatus = "" as callStatusType            
            switch (callInProgressData.status) {
                case "ringing":{
                    newCallStatus=callInProgressData.status
                        enabledCall(callInProgressData)
                }
                break;
                case "up": {
                    newCallStatus= "on call"
                        enabledCall(callInProgressData)
                }
                break;
                case "hangup": {
                    newCallStatus= callInProgressData.direction == 'outbound' ? "standby" : "finished"
                    if(newCallStatus == "standby"){
                        const end = new Date(timeTocall.end)
                        const start = new Date (timeTocall.start)
                        const diffMs:any = (end.valueOf() - start.valueOf());
                        const diffMins = Math.round( ((diffMs % 86400000) % 3600000) / 60000);
                        const argsCallClose: ICall = {
                            type: "update",
                            name: noteData.noteTitle != '' ? noteData.noteTitle: callContactData.name != '' ? `Llamada con ${callContactData.name}` : callContactData.name != '' && callSelectOption == "EXT" ? `Llamada con ext ${callContactData.phoneNumber} ` : `Llamada con ${callContactData.phoneNumber} `,
                            status: "Held",
                            start: callSaveId.dateStart ?  callSaveId.dateStart : timeTocall.start,
                            description: noteData.noteDescription ? noteData.noteDescription : "",
                            duration: diffMins,
                            relatedTo: {
                                recordId: callContactData.idCrm ? callContactData.idCrm : "",
                                module: callContactData.type ? callContactData.type : "",
                            }
                        }
                        if(callInProgressData.pid){
                            argsCallClose.end = timeTocall.end,
                            savecall(argsCallClose, callSaveId.id != "" &&  callSaveId.id != undefined ? callSaveId.id : callInProgressData.pid)
                        }
                        cleanStates()
                    }
                }
                    break;
                default:
                    break;
            }
            setCallStatus(newCallStatus)
            setCallIsOutbound(callInProgressData.direction == 'outbound' ? true:false)
        }
    }, [callInProgressData])

    //Cambiando estado de la vista actual
    useEffect(()=>{
        if(callInProgress && actualView != "Call"){
            setActualView("Call")
        }else{
            setActualView("Home")
        }
    }, [callInProgress])

    /**
     * Callback para recibir el evento de link record 
     * @param {String} record string del evento
     */
    const handleRecordMenuConection = (record: string) => 
    {
        if (record == "link current record") 
        {
            if (window && inIframe) {
                const identify = {
                    who: "app-cti",
                    action: "getActualSite",
                    platform: "sugar"
                }
                window.parent.postMessage(identify, "*");

                // Get event listener
                window.addEventListener('message', (msg) => 
                {
                    if (msg.data.from == "widgetIframeActualSite") {
                        const record = msg.data.data.hash
                        if (record) {
                            const urlData = record.split("/")
                            const getModule = urlData[0].split("#")
                            if (getModule[1] != undefined && urlData[1] != undefined) {
                                if (getModule[1] != "Home" && getModule[1] != "bwc") {
                                    // if(callByContactList){    //En caso de relacionar al Contacto
                                        // linkRecordData(callContactData.idCrm, callContactData.type, urlData[1], getModule[1], setResponseSaveLinkRecord) 
                                    // }else{        //En caso de relacionar a Llamada
                                        linkRecordData(urlData[1], getModule[1], callSaveId.id, callSaveId.module, setResponseSaveLinkRecord) 
                                    // }
                                }else{
                                    openAlertMessage("error", "Could not link the record because you are not on a record or the record is not linkable")
                                }
                            }else{
                                openAlertMessage("error", "Could not link the record because you are not on a valid URL ")
                            }
                        }
                    }
                }, false);
            }else{  
                openAlertMessage("error", "Could not link the record")
            }
        }
    }
    /** 
     * Callback para recibir el texto ingresado en componente `<TextArea>` de la Nota en CallScreen  
     * @param {String} noteData Cadena de Texto ingresado
     */
    const handleTextNote = (noteData: INoteCall) => {
        if (callSaveId.id) {
            const argsCall:ICall = {
                type: "update",
                name: noteData.noteTitle != '' ? noteData.noteTitle : callSaveId.name,
                status: "Planned", 
                start: callSaveId.dateStart,
                description: noteData.noteDescription != '' ? noteData.noteDescription : callSaveId.description,
                duration: callSaveId.durationMinutes,
                relatedTo: {
                    recordId: callContactData.idCrm ? callContactData.idCrm : "",
                    module: callContactData.type ? callContactData.type : "",
                }
            }
            // if(noteData.noteTitle != callSaveId.name || noteData.noteDescription != callSaveId.description)
            // {
                savecall(argsCall, callSaveId.id)
            // }
        }
        setNoteData(noteData)
    }

    const displayDialog = (str: string): Promise<boolean> => {
        setDisplayConfirmDialogFlag(true);
        return new Promise((resolve: any) => {
            const data = {
                text: str,
                handleAccept: resolve
            }
            setConfirmDialogContainer(ConfirmDialog(data));            
        });
    }

    const handleLinkCallback = async(relatedLinkRecord: IRecordData) => {
        if (Object.keys(callSaveId).length != 0 && callSaveId.id != '') 
        {
            try
            {
                const resp = await displayDialog("The contact will be linked to the call, Do you want to continue?") 
                if (resp) 
                {
                    openAlertMessage("info", "Requesting to link contact...") 
                    linkRecordData(relatedLinkRecord.idCrm, relatedLinkRecord.type, callSaveId.id, callSaveId.module, setResponseSaveLinkRecord)
                }
            }
            catch(error)
            {
                console.error("handleLinkCallback", error);
            }
            finally
            {
                setConfirmDialogContainer(null);
                setDisplayConfirmDialogFlag(false);
            }
        }
        else
        {
            openAlertMessage("error", "Could not link the record")
        }
    }

    /**
     * Callback para gestionar los datos de la actividad selecciona de las ultimas 5 actividades del contacto de la llamada
     * @param record Datos con valores del registro  de la actividad seleccionada
     */
    const handleTimeLineClick = (record: IRelatedActivitiesData) => {
        if (userAccessData) {
            const site = userAccessData.pathname ? userAccessData.baseUrl+userAccessData.pathname : userAccessData.baseUrl;
            const isHttp = /https?:\/\//;
            // var isOndemand = /sugarondemand/;
                if (isHttp.test(site)) {
                    window.open(`${site}/#${record.module}/${record.idCrm}`)
                }
        }
    }

    /**
     * Callback para recibir el registro a buscar ingresado en input `<SearchInput>` (CRM Search)
     * @param searchRecord Cadena de texto del registro a buscar
    */
    const handleSearchClick = (searchRecord: string) => {
        setSearchActive(true)
            searchcontact(searchRecord, setResponseSearchContact)
    }

    const handleSearchRelatedClick = (searchRecord: string) => {
        setRelatedStatus("loading")  
        // setTimeout(() => {
            searchcontact(searchRecord, setResponseSearchRelatedRecords) 
        // }, 2000);
    }
    
    /**
     * Callback para recibir la opcion seleccionada para llamada, phone o ext
     * @param data Cadena de texto con la opcion seleccionada`
    */
     const handleCallOption = (callOptionSelected: string) => {
        setCallSelectOption(callOptionSelected)
    }

    
    /**
     * Callback para recibir ext en input `<Ext Number>`
     * @param extNumber numero de extencion`
    */
     const handleExtCall = (extNumber: string) => {
        // Guardando Numero de ext
        setCallExt(extNumber)
    }

    /**
     * Callback para recibir numero valido en input `<PhoneInput>`
     * @param data Datos del numero a marcar `IPhoneData: {isValid, phoneNumber, country}`
    */
    const onValidPhone = (outgoingCall: INumberCodeCountry) => {
        // Guardando Numero Valido
        setValidNumber(outgoingCall)
    }

    /**
    * Función para detonar la llamada desde botón de lista de Contactos`
    * @param data información del contacto existente
    * @param phoneNumber Numero del contacto existente
   */
    const dialClickHandlerByContactList = (contactData: IContactCallData, phoneNumber: INumberCodeCountry) => {
        if(isClic2Call){
            setCallContactData(contactData)
            // setCallByContactList(true) //Cambiar bandera a true para conocer que la llamada fue detonada por contact list
            if(contactData.phoneNumber.length <= 5){
                sendcall(contactData, userExt, setResponseSendCall);
            }else{
                sendcall(contactData, userExt, setResponseSendCall, phoneNumber);
            }
            openAlertMessage("info", "Directing call to your extension")  
        }else{
            openAlertMessage("error", "It is not possible to initiate the call because the 'click 2 call' is not enabled.")
        }
    }

    /**
     * Funcion para detonar la llamada desde botón <BottomBar>`
     * 
    */
   const dialClickHandler = () => {
    // console.log("Detonando llamada por Boton")
    const contactData: IContactCallData = { name: '', phoneNumber: validNumber.phoneNumber, type: '', idCrm: '' }
        if(callSelectOption != '' ){
            if(callSelectOption == "EXT" && callExt != '' ){
                 contactData.phoneNumber = callExt ;
                 contactData.ext = true 
            }
        }
        if(contactData.phoneNumber != undefined  && contactData.phoneNumber != '' && isValidNumber == true){
            setCallContactData(contactData)
            if(isClic2Call){
                if(contactData.ext){
                    sendcall(contactData, userExt, setResponseSendCall);
                }else{
                    sendcall(contactData, userExt, setResponseSendCall, validNumber);
                }
                openAlertMessage("info", "Directing call to your extension")
            }else{
                openAlertMessage("error", "It is not possible to initiate the call because the 'click 2 call' is not enabled.")
            }
        }
    }

    /**
     * Funcion para Cambiar a vista CallScreen sea por llamada recibida, llamada desde numero o llamada desde lista de contactos
     * @param callContactData información del contacto de la llamada
     * @param related true si es un contacto relacionado o false en caso de que no lo sea
     * @param isOutbound true si es una llamada emitida desde CTI o false en caso de ser entrante
     * @param numberCall? Datos del telefono (country,isValid, number) en caso de ser detonado desde CTI
    */
    const startCallScreen = async(callContactDataInfo: IContactCallData, related: boolean) => {
        //Cambiar estado de contacto relacionado
        if (related) {
            setContactIsRelated(true)
        } else {
            setContactIsRelated(false)
        }
        // setValueNoteTitle(callContactData.name != '' ? `Llamada con ${callContactData.name}` : callContactData.name != '' && callSelectOption == "EXT" ? `Llamada con ext ${callContactData.phoneNumber} ` : `Llamada con ${callContactData.phoneNumber} `)
        setCallInProgress(true)
        changeEnabledCall(true)

        // Detonando busqueda de relacionados al Backend 
        if(callContactDataInfo.idCrm == ''){
            if(validNumber && validNumber.country && validNumber.country.countryCode)
            {
                const code: string = validNumber.country.countryCode.toString() as string;
                if (callContactDataInfo.phoneNumber.includes("+") && callContactDataInfo.phoneNumber != '') {
                    callContactDataInfo.phoneNumber = callContactDataInfo.phoneNumber.split(" ").join("");
                    const length = callContactDataInfo.phoneNumber.length - (code.length + 1); // +1 quita el símbolo "+"
                    callContactDataInfo.phoneNumber = callContactDataInfo.phoneNumber.substring(callContactDataInfo.phoneNumber.length - length)
                    setCallContactData({ ...callContactData,
                        phoneNumber: callContactDataInfo.phoneNumber
                    })           
                }
            }
            setRelatedStatus("loading")
            searchcontact(callContactDataInfo.phoneNumber, setResponseSearchRelatedRecords)  //Solo para pruebas    
        }

        if (inIframe && window) {
            // send Peticion to iframe
            const identify = {
                who: "app-cti",
                action: "openFrameinRingingCall",
                platform: "sugar"
            }
            window.parent.postMessage(identify, "*");
        }
        
        // Detonando evento de guardar llamada al  Backend 
        const argsCall: ICall = {
            type: "create",
            recordId: callInProgressData.pid,
            name: callContactDataInfo.name != '' ? `Llamada con ${callContactDataInfo.name}` : callContactDataInfo.name != '' && callSelectOption == "EXT" ? `Llamada con ext ${callContactDataInfo.phoneNumber} ` : `Llamada con ${callContactDataInfo.phoneNumber} `,
            status: "Planned",
            start: (new Date()).toISOString(),
            duration: 1,
            description: "",
            relatedTo: {
                recordId: callContactData.idCrm ? callContactData.idCrm : "",
                module: callContactData.type ? callContactData.type : "",
            }
        }
        setTimeToCall({
            ...timeTocall,
            start: (new Date()).toISOString()
        })
        savecall(argsCall)

        // Detonando busqueda de ultimas actividades al Backend 
        if (Object.keys(callContactData).length != 0 && callContactData.idCrm) {
            setCtlStatus("loading")
            searchlastactivities(callContactData, setResponseLastActivities)
        }
    }

    /**
     * Función para Terminar la llamada`
     * 
    */
    const callEndClickHandler = () => {
        // console.log("Terminando llamada por Boton")
        if(noteData.noteTitle == '' && noteData.noteDescription != ''){
            openAlertMessage("error", "The field Title is required")
        }
        else if(noteData.noteTitle != '' && noteData.noteDescription == ''){
            openAlertMessage("error", "The field Description is required")
        }else if(noteData.noteTitle == '' && noteData.noteDescription == ''){
            openAlertMessage("error", "There are required fields without filling")
        }else if(callStatus != "finished"){
            openAlertMessage("error", "The call has not ended")
        }
        else{
            hangupcall(callContactData, userExt, setResponseHangupCall)
        }
    }

     /**
     * Función para cerrar vista de llamada`
     * 
    */
    const callCloseClickHandler = () => {
        callEndClickHandler()
    }

        
    /**
     * Funcion para contestar la llamada desde botón <BottomBar>`
     * 
    */
     const dialClickAnswerCall = () => {
        answercall(userExt, callInProgressData.cid, setResponseAnswerdCall);
    }

    /**
     * Callback para habilitar o deshabiliar botón de iniciar Llamada `
     * @param {boolean} data Bandera true o false 
    */
    const changeEnabledCall = (data: boolean) => {
        if (data) {
            setIsValidNumber(true)
        } else {
            setIsValidNumber(false)
        }
    }
    function cleanStates()  {
        setCallSaveId({} as IsavedCall);
        setCallContactData({} as IContactCallData)
        setcallInProgressData({} as ICallInProgressData)
        setValidNumber(Object);
        setCallInProgress(false)
        changeEnabledCall(false)
        setListContacts([])
        setRelatedStatus('' as statusSearch)
        setCtlStatus('' as statusSearch)
        setContactIsRelated(false)
        setTimeRecords([] as IRelatedActivitiesData[])
        setRelatedRecords([] as CrmRecordData[])
        setResponseAnswerdCall({})
        setResponseHangupCall({})
        setResponseSendCall({})
        setResponseSaveCall({})
        setResponseSearchContact({} as responsePlatform)
        setResponseSearchRelatedRecords({} as responsePlatform)
        setResponseLastActivities({})
        setResponseSaveLinkRecord({})
        setResponseRelateRecord({})
        setResponseRelateRecordCalltoContact({})
        setCallExt('');
        setTimeToCall({
            start: "",
            recordStart: "",
            end: "",
            recordEnd: "",
            duration: ""
        })
        // setCallByContactList(false)
        setNoteData({
        noteDescription: '',
        noteTitle: ''
        })
        setCathRingingCall({} as IResponse);
        setCatchUpCall({} as IResponse);
        setcathFinishedcall({} as IResponse);
        setCallSelectOption('');
        setCallIsOutbound(false)
    }

    const linkRecordData = (idParent:string, moduleParent:string, idChild:string, moduleChild:string, callback: (value: any) => void) =>{
        const relIds = [] as any[];
        const parentRelatedRecord: IParentRelatedRecord = {
            id: idParent,
            module: moduleParent
        }
        const childRelatedRecord: IChildRelatedRecord = {
            id: idChild,
            module: moduleChild 
        }  
        relIds.push(childRelatedRecord.id)
        sendlinkrecord(parentRelatedRecord, childRelatedRecord, relIds, callback)
    } 

    const handleConfigOpen = () => {
        setConfigOpen(true);
    };
    const handleConfigClose = () => {
        setConfigOpen(false);
    };
    
    function enabledCall(callData:ICallInProgressData){
        if(!callInProgress){
            let contactData: IContactCallData =
            {
                name: "",
                phoneNumber: callData.cid,
                type: "Ld",
                idCrm: "",
                ext: false
            }
            if(Object.keys(callContactData).length != 0){
                contactData = callContactData
            }else{
                setCallContactData(contactData)
            }
            const related = callContactData.idCrm ? true: false
            setActualView("Call")
            startCallScreen(contactData, related);
        }
    }
    function saveClosedCallScreen(Object:IResponse){
        if(Object.status == 200){
            openAlertMessage("info", "The call has been saved in the CRM")
        }
    }

    //Alert Message
    function openAlertMessage(status:alertStatus, mesage:string){
        setOpenAlert(true),
        setAlertStatus(status)
        setAlertMessage(mesage)
    }
    //Cerrar Alert
    const handleCloseAlert = () => {
        setOpenAlert(false);
    };

    // Funcion para preguntar al iframe si la pestaña de navegacion esta en foco
    // const theTabIsHidden = () =>{
    //     return new Promise(function(resolve, reject) {
    //         let pageHidden: pageFocus = true
    //         if (window) {
    //             // send Peticion to iframe
    //             const identify = {
    //                 who: "app-cti",
    //                 action: "tabIsHidden",
    //                 platform: "sugar"
    //             }
    //             window.parent.postMessage(identify, "*");

    //             // Get event listener
    //             window.addEventListener('message', (msg) => {
    //                 try {
    //                     if (msg.data.from == "getTabIsHidden") {
    //                         pageHidden = msg.data.data
    //                         // console.log("pageHidden is ", pageHidden)
    //                         resolve(pageHidden);        
    //                     }
    //                 } catch (error) {
    //                     reject(true);
    //                 }
    //             }, false);
    //         } else {
    //             reject(true);
    //         }
    //     });
    // }
    // Props to use in Child's Components
    const appBarProps: IAppBarData = { name: userName, ext: userExt, callStatus: callStatus, online: userOnline, isAppOpen: true, appVersion: appVersion, callbackConfigOpen: handleConfigOpen, configOpen: configOpen, userAccessData: userAccessData };
    const dataBottomBar = { callInProgress: callInProgress, dialClickHandler: dialClickHandler, callEndClickHandler: callEndClickHandler, dialClickAnswerCall: dialClickAnswerCall, enableCallBtn: isValidNumber, isOutbound: callIsOutbound, callStatus: callStatus };
    const dataCloseBottomBar = { callInProgress: callInProgress, callCloseClickHandler: callCloseClickHandler, enableCallBtn: isValidNumber };
    const homeScreenProps: IHomeScreenProps = { contacts: listContacts, onValidPhone: onValidPhone, handleSearchEvent: handleSearchClick, searching: searchActive, searchDefaultValue: '', validCallBtn: changeEnabledCall, callByContact: dialClickHandlerByContactList, handleCallOption: handleCallOption, handleExtCall: handleExtCall, dialClickHandler: dialClickHandler };
    const callScreenProps: ICallScreenProps = { callContactData: callContactData, isRelated: contactIsRelated, isOutbound: callIsOutbound, callStatus: callStatus, crmConecction: true, searchRelatedStatus: relatedStatus, searchActivitiesStatus: ctlStatus, timeLineData: timelineRecords, handleTimeLineCallback: handleTimeLineClick, relatedRecordsData: relatedRecords, handleLinkCallback: handleLinkCallback, handleTextNote: handleTextNote, handleRecordMenuConection: handleRecordMenuConection, callSaveData: callSaveId, handleSearchEvent: handleSearchRelatedClick, searchDefaultValue: callContactData.phoneNumber, numberCodeCountry: validNumber };
    const configScreenProps: IconfigScreen = { handleDrawerClose: handleConfigClose, isOpen: configOpen };
    return (
        <div>
            {
                (displayConfirmDialogFlag && confirmDialogContainer) &&
                confirmDialogContainer
            }

            <CtiAppBar {...appBarProps} />
            {configOpen ?
                <div>
                <ConfigScreen {...configScreenProps} />
                </div>
            :
                <div>
                    {!callInProgress ?
                     <HomeScreen {...homeScreenProps} /> : <CallScreen {...callScreenProps}></CallScreen>
                    }
                    {
                        isClic2Call ?
                        // callInProgress ? <ClosedBar {...dataCloseBottomBar} />  :  <BottomBar {...dataBottomBar} />
                        (callStatus == "finished" && callInProgress) ?<ClosedBar {...dataCloseBottomBar} />  :  <BottomBar {...dataBottomBar} />
                        : 
                        <div></div>
                    }
                </div>
            }
            <Snackbar anchorOrigin={{ vertical, horizontal }} open={openAlert} autoHideDuration={3700} onClose={handleCloseAlert}>
                <Alert onClose={handleCloseAlert} severity={alertStatus}>
                    {/* This is a success message! */}
                    {alertMessage}
                </Alert>
            </Snackbar>
        </div>
    )
}