import { Fab } from '@material-ui/core';
import { h } from 'preact';
import useSharedStyles from '../../style/theme';
import { IClosedBarData } from './definition';
import CloseIcon from '@material-ui/icons/Close';

export default function ClosedBar(props: IClosedBarData) : any 
{
    const classes = useSharedStyles();

    return (
        <div className={classes.BB_root} style={{ textAlign: 'center' }}>
            <Fab color="secondary" aria-label="close" onClick={() => props.callCloseClickHandler()} >
                <CloseIcon />
            </Fab>
        </div>
    )
}