import { h } from 'preact'
import { useEffect, useState } from 'preact/hooks'
import Paper from '@material-ui/core/Paper'
import Grid from '@material-ui/core/Grid'
import Avatar from '@material-ui/core/Avatar'
import Chip from '@material-ui/core/Chip'
import Typography from '@material-ui/core/Typography'
import ArrowUpwardIcon from '@material-ui/icons/ArrowUpward';
import ArrowDownwardIcon from '@material-ui/icons/ArrowDownward';
import { ICallBarProps } from './definition'
import RecordItem from '../core/RecordItem/RecordItem'
import MenuLinkRecord from '../core/menuLinkRecord/menuLinkRecord'
import useSharedStyles from '../../style/theme'

// const message = `Truncation should be conditionally applicable on this long line of text as this is a much longer line than what the container can support. `;

export default function CallBar(props: ICallBarProps) : any 
{
    const classes = useSharedStyles();
    const [calllStatus, setCalllStatus] = useState(props.calllStatus);
    
    useEffect(()=>{
        setCalllStatus(props.calllStatus)
    }, [props.calllStatus]);

    const content = <Chip 
        label={calllStatus}
        className={calllStatus == 'ringing' ? classes.ringingColor : calllStatus == 'on call' ? classes.onCallColor : calllStatus == 'hangup' || calllStatus == 'finished' || calllStatus == 'unattended' ? classes.finishedColor : classes.standby}
        icon={props.isOutbound == true ?
            <ArrowUpwardIcon className={calllStatus == 'ringing' ? classes.ringingIconColor : calllStatus == 'on call' ? classes.onCalliconColor : calllStatus == 'hangup' || calllStatus == 'finished' || calllStatus == 'unattended' ? classes.finishedIconColor : classes.standbyIconColor} />
            :
            <ArrowDownwardIcon className={calllStatus == 'ringing' ? classes.ringingIconColor : calllStatus == 'on call' ? classes.onCalliconColor : calllStatus == 'hangup' || calllStatus == 'finished' || calllStatus == 'unattended' ? classes.finishedIconColor : classes.standbyIconColor} />}
        variant="outlined"/>;
        
    if(props.recordData == null){
        return (
            <div></div>
        )
    }
    return (
        <div className={classes.rootCallbar}>
            <Paper className={classes.paperCallBar}>
                {props.existingRecord ?
                    <Grid container wrap="wrap" justifyContent="space-between" alignItems="center" direction="row">
                        <Grid item xs>
                            <RecordItem record={props.recordData} from='Call' content={<Grid item style={{ padding: 7 }}>{content}</Grid>} />
                        </Grid>
                        <MenuLinkRecord crmConnection={props.crmConnection}  handleRecordMenuConection={props.handleRecordMenuConection}/>
                    </Grid>
                    :
                    <Grid container wrap="wrap" justifyContent="space-between" alignItems="center" direction="row">
                        <Grid item xs style={{ display: 'flex' }}>
                            <Avatar className={classes.purple}>{'Ld'}</Avatar>
                            <Typography noWrap style={{ marginTop: '9px', marginLeft: '13px' }}>{props.recordData.phoneNumber}</Typography>
                        </Grid>
                            {content}
                        <MenuLinkRecord crmConnection={props.crmConnection} handleRecordMenuConection={props.handleRecordMenuConection}/>
                    </Grid>
                }
            </Paper>
        </div>
    );
}