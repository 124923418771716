import { IRelatedActivitiesData } from "../../components/contactTimeLine/definition"


const  CTLData: IRelatedActivitiesData[] = [
    { name: "Caso 1434", description: "El sistema me muestra un error 500 al guarda un nuevo cliente", module: "Cases", idCrm: "1234567890", date: "2021-09-10T20:44:13-05:00" },
    { name: "Seguimiento a póliza", description: "Reunión de seguimiento a póliza de soporte", module: "Meetings", idCrm: "2345678901", date: "2021-09-10T20:44:13-05:00" },
    { name: "screenshot", description: "captura de pantalla de error 500", module: "Notes", idCrm: "3456789012", date: "2021-09-10T20:44:13-05:00" },
    { name: "Enviar propuesta de soporte", description: "Enviar porpuesta de bolsa de horas de soporte", module: "Tasks", idCrm: "5678901234", date: "2021-09-10T20:44:13-05:00" },
    { name: "Llamada de seguimiento 1", description: "Llamada para preguntar por firma de contrato 1", module: "Calls", idCrm: "6789012345", date: "2021-09-10T20:44:13-05:00" },
]

export default CTLData

export const LastActivitiesDataDummy : IRelatedActivitiesData[] = [
    { name: "", description: "", module: "", idCrm: "0", date: "2021-09-10T20:44:13-05:00" },
    { name: "", description: "", module: "", idCrm: "1", date: "2021-09-10T20:44:13-05:00" },
    { name: "", description: "", module: "", idCrm: "2", date: "2021-09-10T20:44:13-05:00" },
    { name: "", description: "", module: "", idCrm: "3", date: "2021-09-10T20:44:13-05:00" },
    { name: "", description: "", module: "", idCrm: "4", date: "2021-09-10T20:44:13-05:00" },
]