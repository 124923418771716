import { h } from 'preact'
import { Button, Grid } from '@material-ui/core'
import MoreVertIcon from '@material-ui/icons/MoreVert';
import IconButton from '@material-ui/core/IconButton';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import { useState } from 'preact/hooks'
import { menuConection } from './definition';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import useSharedStyles from '../../../style/theme';


export default function MenuLinkRecordItem(props: menuConection) : any
{
    const classes = useSharedStyles();
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const open = Boolean(anchorEl);
    const ITEM_HEIGHT = 48;
    // const options = ['link current record', 'New Case', 'New Note', 'New Task', 'New Meeting', 'New Call'];
    const options = ['link current record'];
    const [dialogOpen, setDialogOpen] = useState(false);

    const handleClick = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget);
        if (!props.crmConnection && !dialogOpen) {
            setDialogOpen(true)
        }
    };
    const dialogHandleClose = () => {
        setDialogOpen(false);
    }
    const handleClose = () => {
        setAnchorEl(null);
    };

    const handleChange = (event: React.ChangeEvent<any>) => {
        props.handleRecordMenuConection(event.target.id)
        handleClose()
      };
    return (
        <Grid item style={{ padding: 1 }}>
            <IconButton color="primary" aria-label="options" className={classes.moreIcon} component="span" onClick={handleClick}>
                <MoreVertIcon />
            </IconButton>
            {props.crmConnection ?
                <Menu
                    id="long-menu"
                    anchorEl={anchorEl}
                    keepMounted
                    open={open}
                    onClose={handleClose}
                    PaperProps={{
                        style: {
                            maxHeight: ITEM_HEIGHT * 4.5,
                            width: '20ch',
                        },
                    }}
                >
                    {options.map((option) => (
                        <MenuItem key={option} id={option} selected={option === 'Pyxis'} onClick={handleChange}>
                            {option}
                        </MenuItem>
                    ))}
                </Menu> :
                <Dialog
                    open={dialogOpen}
                    onClose={dialogHandleClose}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                >
                    <DialogTitle id="alert-dialog-title">{"There is no connection to the CRM"}</DialogTitle>
                    <DialogContent>
                        <DialogContentText id="alert-dialog-description">
                            Please, before generating an action, first verify your connection with the CRM.
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={dialogHandleClose} color="primary" autoFocus>
                            Agree
                        </Button>
                    </DialogActions>
                </Dialog>
            }
        </Grid>
    )
}