import { createStyles, makeStyles, Theme } from '@material-ui/core/styles'
import { purple, red, green, yellow } from '@material-ui/core/colors'

const useSharedStyles = makeStyles((theme: Theme) =>
  createStyles({
    textField: {
      "& input::-webkit-clear-button, & input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button": {
              display: "none"
       }
    },
    root: {
      marginLeft: '2%',
      marginRight: '2%',
    },
    rootMenuRecord: {
      flexGrow: 1,
      overflow: 'hidden',
    },
    rootRelatedRecords: {
      backgroundColor: theme.palette.background.paper,
      flexGrow: 1,
    },
    rootNote: {
      marginTop: "2px !important",
    },
    rootCallbar: {
      flexGrow: 1,
    },
    rootDiv: {
      marginLeft: '2%',
      marginRight: '2%',
      maxHeight: '150px',
      overflow: 'auto !important',
      marginBottom: '1em',
      marginTop: '1em',
    },
    rootFormNote: {
      backgroundColor: "red",
      marginLeft: '2% !important',
    },
    rootTextAreaBox: {
      backgroundColor: "#ffffff",
      borderRadius: "3px",
      width: '100%',
      maxWidth: '100%',
      minWidth: '28px',
      minHeight: '28px',
      color: "#555",
      fontSize: "14px",
      fontFamily: '"Open Sans","Helvetica Neue",Arial,sans-serif',
      boxShadow: '0px 2px 1px -1px rgb(0 0 0 / 20%), 0px 1px 1px 0px rgb(0 0 0 / 14%), 0px 1px 3px 0px rgb(0 0 0 / 12%)',
    },
    rootPaper: {
      flexWrap: 'wrap',
      '& > *': {
        height: theme.spacing(16),
      },
    },
    rootMenu: {
      marginLeft: '2%',
      marginRight: '2%',
      backgroundColor: theme.palette.background.paper,
    },
    rootConfig: {
      margin: theme.spacing(4),
      marginTop: "0px",
      maxWidth: "100%"
    },
    rootConfigCloseBar: {
      marginLeft: theme.spacing(4),
      marginRight: theme.spacing(4),
      maxWidth: "100%"
    },
    rootPlatformInfo: {
      // padding: theme.spacing(2),
      margin: theme.spacing(4),
      maxWidth: "100%",
    },
    rootListPlatforms: {
      margin: theme.spacing(4),
      backgroundColor: theme.palette.background.paper,
    },
    rootAgent: {
      margin: theme.spacing(4),
      flexGrow: 1,
    },
    paperAgent: {
      padding: theme.spacing(4),
      maxWidth: "100%",
    },
    cardPlatform: {
      margin: `${theme.spacing(2)}px auto !important`,
      padding: `${theme.spacing(2)}px !important`,
    },
    paperCallBar: {
      marginLeft: '2%',
      marginRight: '2%',
      paddingLeft: `${theme.spacing(2)}px !important`,
      paddingRight: `${theme.spacing(2)}px !important`,
    },
    gridItem: {
      margin: '-3px !important',
    },
    gridPhone: {
      marginLeft: '0px !important',
    },
    orange: {
      backgroundColor: '#ffdebc !important',
      color: '#9b4617 !important',
    },
    blue: {
      backgroundColor: "#c0edff !important",
      color: "#145c95 !important",

    },
    pink: {
      backgroundColor: "#f7d0fd !important",
      color: "#832a83 !important",
    },
    red: {
      color: theme.palette.getContrastText(red[500]).concat(" !important"),
      backgroundColor: red[500].concat(" !important"),
    },
    purple: {
      // backgroundColor: deepPurple[100],
      backgroundColor: "#e2d4fd !important",
      color: purple[900].concat(" !important"),
    },
    green: {
      backgroundColor: '#baffcc !important',
      color: '#056f37 !important',
      // border: `2px solid ${green[500]}`,
    },
    aqua: {
      backgroundColor: "#c5fffb !important",
      color: "#00636e !important",
    },
    yellow: {
      color: theme.palette.getContrastText(yellow[500]).concat(" !important"),
      backgroundColor: yellow[500].concat(" !important"),
    },
    standby: {
      color: "#034680 !important",
      border: `2px solid #096398 !important`,
    },
    standbyIconColor: {
      color: "#034680 !important",
    },
    onCallColor: {
      color: theme.palette.getContrastText(green[600]).concat(" !important"),
      border: `2px solid ${green[500]} !important`,
      backgroundColor: green[500].concat(" !important")
    },
    onCalliconColor: {
      color: theme.palette.getContrastText(green[600]).concat(" !important"),
    },
    finishedColor: {
      color: "#d41135 !important",
      border: `2px solid #d41135 !important`,
    },
    finishedIconColor: {
      color: "#d41135 !important",
    },
    ringingColor: {
      color: `${green[500]} !important`,
      border: `2px solid ${green[500]} !important`,
    },
    ringingIconColor: {
      color: `${green[500]} !important`,
    },
    phone_content: {
      textAlign: 'right',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
    },
    recordPhoneContent: {
      textAlign: 'right',
    },
    backdrop: {
      zIndex: theme.zIndex.drawer + 1,
      color: "#fff"
    },
    message: {
      textAlign: 'center',
    },
    max_text: {
      noWrap: true,
      overflow: 'hidden !important',
      textOverflow: 'ellipsis !important',
      // maxWidth:'50%'
    },
    // estilos para el componente ContactsTimeLine (CTL)
    CTL_paper: {
      padding: '3px 8px',
      minWidth: '100px',
      height: '100px',
      overflow: 'scroll',
      overflowX: "hidden",
    },
    CTL_backdrop: {
      color: '#fff',
    },
    CTL_backdrop_container: {
      position: "relative",
      height: '100px'
    },
    CTL_container: {
      marginLeft: '2%',
      marginRight: '2%',
      // overflow: 'scroll',
      overflowX: "hidden",
    },
    // ----------

    // componente bottomBar
    BB_root: {
      '& > *': {
        margin: theme.spacing(1),
      },
    },
    BB_extendedIcon: {
      marginRight: theme.spacing(1),
    },
    // ----------

    // componente searchInput
    SI_searchContainer: {
      marginLeft: '2%',
      marginRight: '2%',
      marginTop: '1em'
    },
    // ----------
    defaultColor: {
      color: '#1c4865',
    },
    defaultAvatarColor: {
      color: '#1c4865',
      border: '2px solid #1c4865',
      backgroundColor: '#ffffff',
    },
    moreIcon: {
      padding: 10
    },
    // Note 
    noteTitle: {
      backgroundColor: "#ffffff",
      color: "#555 !important",
      fontSize: "14px !important",
      borderRadius: "3px",
      fontFamily: '"Open Sans","Helvetica Neue",Arial,sans-serif !important',
    },
    // componente config
    avatar: {
      backgroundColor: `${red[500]} !important`,
    },
    dafaultTextField: {
      marginLeft: theme.spacing(2),
      marginRight: theme.spacing(2),
      maxWidth: "100px"
    },
    conectionPlatform: {
      maxWidth: "100%"
    },
    cardTitle: {
      backgroundColor: "black", 
      color: "aliceblue"
    },
    cardContent: {
      backgroundColor: "white"
    },
    // Para componente collapse
    expand: {
      transform: 'rotate(0deg)',
      marginLeft: 'auto',
      transition: theme.transitions.create('transform', {
        duration: theme.transitions.duration.shortest,
      }),
    },
    expandOpen: {
      transform: 'rotate(180deg)',
    },
    paper: {
      paddingLeft: theme.spacing(2),
      color: theme.palette.text.secondary,
    },
    rootCtlTab: {
      flexGrow: 1,
    },
    inline: {
      display: 'inline',
    },
    verticalroot: {
      flexGrow: 1,
      backgroundColor: theme.palette.background.paper,
      display: 'flex',
      height: 224,
    },
    tabs: {
      borderRight: `1px solid ${theme.palette.divider}`,
    },
    button: {
      margin: theme.spacing(1),
      padding: "0px 4px" 
    },
    //Input search
    rootSearch: {
      width: '100%',
      padding: '2px 4px',
      marginBottom: "5px",
      display: 'flex',
      alignItems: 'center',
    },
    input: {
      marginLeft: theme.spacing(1),
      flex: 1,
    },
    divider: {
      height: "28px !important",
      margin: "4 !important",
    },
  }),

);

export default useSharedStyles;