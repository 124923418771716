import { h } from 'preact'
import { Box, Paper, Tab, Tabs, Typography } from '@material-ui/core'
import Skeleton from '@material-ui/lab/Skeleton';
import { TabPanelProps } from '../../contactTimeLine/definition'
import { useState } from 'preact/hooks'
import LoadingListItem from '../LoadingListItem/LoadingListItem';
import { LastActivitiesDataDummy } from '../../../utils/Data/contactTimeLineData';

function TabPanel(props: TabPanelProps)
{
    const { children, value, index, ...other } = props;
    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`scrollable-force-tabpanel-${index}`}
            aria-labelledby={`scrollable-force-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box p={3}>
                    <Typography>{children}</Typography>
                </Box>
            )}
        </div>
    );
}
function a11yProps(index: any) {
    return {
        id: `scrollable-force-tab-${index}`,
        'aria-controls': `scrollable-force-tabpanel-${index}`,
    };
}

export default function LoadingTabsItem() : any
{
    const [value, setValue] = useState(0);
    const handleChange = (event: React.ChangeEvent<any>, newValue: number) => {
        setValue(newValue);
    };
    return (
        <Paper square >
            <Tabs
                id="TabPanel"
                value={value}
                onChange={handleChange}
                variant="scrollable"
                scrollButtons="off"
                indicatorColor="primary"
                textColor="primary"
                aria-label="icon label tabs example"
            >
                {LastActivitiesDataDummy.map((dummy) =>
                    <Tab key={dummy.idCrm} disabled label={<Skeleton variant="text" style={{ width: "35%", height: "15px" }} />} icon={<Skeleton variant="circle" width={30} height={30} />} {...a11yProps(0)} />
                )}
            </Tabs>
            {LastActivitiesDataDummy.map((dummy, i) =>
                <TabPanel key={dummy.idCrm} value={value} index={i}>
                    <LoadingListItem />
                </TabPanel>
            )}
        </Paper>
    );
}