import { h } from 'preact'
import { useEffect, useState } from 'preact/hooks'
import ListItem from '@material-ui/core/ListItem'
import ListItemText from '@material-ui/core/ListItemText'
import ListItemAvatar from '@material-ui/core/ListItemAvatar'
import IconButton from '@material-ui/core/IconButton'
import CallIcon from '@material-ui/icons/Call';
import Avatar from '@material-ui/core/Avatar'
import { createTheme, Grid, ThemeProvider, Typography } from '@material-ui/core'
import { IRecordView } from './definition';
import { colors } from '../../../utils/types/lists.types'
import useSharedStyles from '../../../style/theme'
import PhoneEnabledIcon from '@material-ui/icons/PhoneEnabled'
import ContactPhoneIcon from '@material-ui/icons/ContactPhone';
import { sugarcrmModules } from '../../../utils/types/sugarCrm.modules.types'
import { IContactData } from '../../contactList/definition'


const theme = createTheme();

export default function RecordItem(props: IRecordView): any {
    const [recordPhonesLength, setRecordPhonesLength] = useState<null | number>(null);
    const [recordPhonesData, setRecordPhonesData] = useState({
        phoneType: "",
        phoneNum: ""
    });

    useEffect(() => {
        if (Object.keys(props.record).length != 0) {
            // Buscar el número de contactos con un número telefónico asignado
            const result = searchContactsWithPhone(props.record);
            setRecordPhonesLength(result.phones);
            if (result.phones != null && result.phones == 1) {
                setRecordPhonesData({
                    phoneType: result.type,
                    phoneNum: result.phone
                })
            }
        }
    }, [props.record]);

    const searchContactsWithPhone = (contact: IContactData) => {
        let phones = 0;
        let type = "";
        let phone = "";
        // if(contact.phone) phones++;
        // if(contact.phoneOther) phones++;        
        if (contact.phoneHome !== "") {
            type = "phoneHome",
                phone = contact.phoneHome != undefined ? contact.phoneHome : "",
                phones++
        }
        if (contact.phoneMobile !== "") type = "phoneMobile", phone = contact.phoneMobile, phones++;
        if (contact.phoneWork) type = "phoneWork", phone = contact.phoneWork, phones++;
        // if(contact.phoneWork && contact.phoneWork.trim() !== "") phones++;

        const recordData = {
            "phones": phones,
            "type": type,
            "phone": phone
        }
        return recordData
    }

    const classes = useSharedStyles();
    const avatarLbl = props.record.type == ("Cases" as sugarcrmModules) ? "Cs" : props.record.type == ("Leads" as sugarcrmModules) ? "Ld" : props.record.type.substr(0, 2);
    return (
        <ThemeProvider theme={theme}>
            <Grid container spacing={4}
                justifyContent="flex-start"
                alignItems="center" className={classes.gridItem}>
                {/* Avatar Seccion*/}
                <ListItemAvatar>
                    {props.from == "Home" || props.from == "Call" ?
                        <Avatar
                            style={{ marginLeft: '1px' }}
                            className={avatarLbl == 'Co' ? classes.pink : avatarLbl == 'Ld' || avatarLbl == 'Ta' ? classes.purple : avatarLbl == 'Cs' ? classes.orange : avatarLbl == 'No' || avatarLbl == 'Ac' ? classes.green : avatarLbl == 'Me' || avatarLbl == 'Us' ? classes.aqua : avatarLbl == 'Ll' ? classes.blue : classes.defaultAvatarColor} >
                            {avatarLbl != '' ? avatarLbl : <CallIcon />}
                        </Avatar>
                        :
                        props.avatar != undefined ?
                            <Avatar className={classes[props.avatar.toLowerCase() as colors]} >{props.avatarLbl}</Avatar>
                            :
                            <Avatar className={classes.purple} >{'Ld'}</Avatar>
                    }
                </ListItemAvatar>
                {/* Text Seccion*/}
                {props.from == "Home" ?
                    <ListItemText className={classes.max_text} id={props.record.idCrm}
                        primary={
                            <Typography variant="caption">{props.record.name}</Typography>}
                    />
                    :
                    <ListItemText
                        primary={<Typography variant="body2">{props.record.name}</Typography>}
                        secondary={<Typography variant="caption"> {props.record.phoneNumber}</Typography>} />
                }
                {/* Botton Seccion*/}
                {props.callBack != undefined && props.from != "Home" ?
                    <IconButton edge="end" aria-label="iconBtn" onClick={props.callBack(props.record)}>
                        {props.content}
                    </IconButton>
                    :
                    props.callBack != undefined && props.from == "Home" ?
                        <div id="buttonEnd" style={{ display: "flex" }}>
                            {
                                recordPhonesLength != null && recordPhonesLength == 1 ?
                                    <ListItem>
                                        <ListItemText primary={<Typography variant="body2">{recordPhonesData.phoneType}</Typography>} secondary={<Typography variant="caption"> {recordPhonesData.phoneNum}</Typography>} />
                                        <IconButton edge="end" aria-label="iconBtn" onClick={props.callBack(props.record, "onlyPhoneWork", recordPhonesData.phoneNum)}>
                                            <PhoneEnabledIcon color="primary" />
                                        </IconButton>
                                    </ListItem>
                                    : recordPhonesLength != null && recordPhonesLength > 1 ?
                                        <ListItem>
                                            <IconButton edge="end" aria-label="iconBtn" onClick={props.callBack(props.record, "morePhone")}>
                                                <ContactPhoneIcon color="primary" />
                                            </IconButton>
                                        </ListItem>
                                        :
                                        <ListItemText primary={<Typography variant="body2">No phone</Typography>} secondary={<Typography variant="caption"> number</Typography>} />
                            }
                            {/* {(props.record.socialNetworks != undefined && props.record.socialNetworks.twitterId != undefined && props.record.socialNetworks.twitterId != null) && 
                        <IconButton edge="end" aria-label="iconBtn" style={{height: "45px",top:"5px"}} onClick={props.callBack(props.record, "twitter")}>
                            <TwitterIcon style={{color:"#1976d2"}}/>
                        </IconButton>
                        } */}
                        </div>
                        :
                        <div style={{ marginRight: '5px' }}>
                            {props.content}
                        </div>
                }

            </Grid>
        </ThemeProvider>
    );
}