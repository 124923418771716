import { h } from 'preact';
import { useEffect, useState } from 'preact/hooks';
import CallBar from '../../components/callBar'
import ContactTimeLine from '../../components/contactTimeLine'
import TextareaAutosize from '@material-ui/core/TextareaAutosize';
import Typography from '@material-ui/core/Typography';
import { ICallScreenProps, INoteCall } from '../interfaces';
import { Grid, InputBase } from '@material-ui/core';
import useSharedStyles from '../../style/theme';
import { IContactTimeLineProps } from '../../components/contactTimeLine/definition';
import { ICallBarProps } from '../../components/callBar/definition';
import { IRelatedListProps } from '../../components/relatedRecordsList/definition';
import SearchInput from '../../components/searchInput';
import RelatedRecordsList from '../../components/relatedRecordsList';

export default function CallScreen(props:ICallScreenProps ) : any
{
    const classes = useSharedStyles()

    // const [callContact,setCallContact] = useState(props.callContactData);
    const [noteInfo, setNoteInfo] = useState({
        noteDescription: '',
        noteTitle: props.callContactData.name != '' ? `Llamada con ${props.callContactData.name}`: props.callContactData.name == '' && props.callContactData.phoneNumber != '' ? `Llamada con ${props.callContactData.phoneNumber}` : ''
    } as INoteCall) 
    const [tempNoteInfo, setTempNoteInfo] = useState({
        noteDescription: '',
        noteTitle: props.callContactData.name != '' ? `Llamada con ${props.callContactData.name}`: props.callContactData.name == '' && props.callContactData.phoneNumber != '' ? `Llamada con ${props.callContactData.phoneNumber}` : ''
    } as INoteCall) 
    const [changeNoteInfo, setChangeNoteInfo] = useState({
        noteDescription: '',
        noteTitle: props.callContactData.name != '' ? `Llamada con ${props.callContactData.name}`: props.callContactData.name == '' && props.callContactData.phoneNumber != '' ? `Llamada con ${props.callContactData.phoneNumber}` : ''
    } as INoteCall) 
    const [changeNoteFocus, setChangeNoteFocus] = useState({
        noteDescription: false,
        noteTitle: false 
    }) 
    const searchActive = props.searchRelatedStatus == 'loading'?true:false
    const isLoading = props.searchActivitiesStatus == 'loading'?true:false

    useEffect(()=>{
        if(props.callSaveData.description != undefined && props.callSaveData.description != changeNoteInfo.noteDescription && changeNoteFocus != undefined && !changeNoteFocus.noteDescription){
            setChangeNoteInfo({
                ...changeNoteInfo,
                noteDescription: props.callSaveData.description
            })
            setTempNoteInfo({
                ...tempNoteInfo,
                noteDescription: props.callSaveData.description
            })
         }
         if(props.callSaveData.name != undefined && props.callSaveData.name != changeNoteInfo.noteTitle &&  changeNoteFocus != undefined && !changeNoteFocus.noteTitle){
           setChangeNoteInfo({
               ...changeNoteInfo,
               noteTitle: props.callSaveData.name
           })
           setTempNoteInfo({
               ...tempNoteInfo,
               noteTitle: props.callSaveData.name
           })
        }
    }, [props.callSaveData])

    useEffect(()=>{
        // if(noteInfo.noteTitle != '' && noteInfo.noteDescription != '')
        // {
            props.handleTextNote(noteInfo) 
        // }
    }, [noteInfo])

    useEffect(()=>{
        if(tempNoteInfo.noteTitle != noteInfo.noteTitle ){
            setNoteInfo({ ...noteInfo,
                noteTitle: tempNoteInfo.noteTitle
            })
        }
        if(tempNoteInfo.noteDescription != noteInfo.noteDescription){
            setNoteInfo({ ...noteInfo,
                noteDescription: tempNoteInfo.noteDescription
            })
        }
    }, [tempNoteInfo])

    const handleOnBlurTextNote = (e: React.ChangeEvent<any>) => {
        if(e.target.id == "noteDescription"){
            setTempNoteInfo({
                ...tempNoteInfo,
                [e.target.id]: e.target.value,
                noteTitle: changeNoteInfo.noteTitle
            })
            setChangeNoteFocus({ ...changeNoteFocus,
                noteDescription: false
            })
        }else{
            setTempNoteInfo({
                ...tempNoteInfo,
                [e.target.id]: e.target.value,
                noteDescription: changeNoteInfo.noteDescription
            })
            setChangeNoteFocus({ ...changeNoteFocus,
                noteTitle: false
            })
        }
    }
    const handleChangeTextNote = (e: React.ChangeEvent<any>) => {
        setChangeNoteInfo({
            ...changeNoteInfo,
            [e.target.id]: e.target.value
        })
    }
    const handleFocusTextNote = (e: React.ChangeEvent<any>) => {
        setChangeNoteFocus({
            ...changeNoteFocus,
            [e.target.id]: true
        })
    }
    const handleSearch = (data:string) => {
        props.handleSearchEvent(data)
    }
    const dataSearchInput = { lbl: "Contact Search", initialValue: props.callContactData.phoneNumber ||"", showIcon: true, disableSearch: searchActive, onSearch: handleSearch };
    const callBarProps:ICallBarProps = { existingRecord: props.isRelated, handleRecordMenuConection: props.handleRecordMenuConection,  calllStatus: props.callStatus, isOutbound: props.isOutbound, recordData: props.callContactData, crmConnection: props.crmConecction }
    const relatedRecordsProps:IRelatedListProps = { callInProgress: true, handleLinkCallback: props.handleLinkCallback, searchState: props.searchRelatedStatus, records: props.relatedRecordsData }
    const timeLineProps:IContactTimeLineProps = { isLoading: isLoading, records: props.timeLineData, handleClick: props.handleTimeLineCallback }
    return (
        <div>
            <CallBar  {...callBarProps} />
            { !props.isRelated ?
                <span>
                    <SearchInput {...dataSearchInput} />
                    <RelatedRecordsList {...relatedRecordsProps} />
                </span>
                :
                <span>
                <Typography  className={classes.root} variant="h6"> Last activities</Typography>
                <ContactTimeLine {...timeLineProps} />
                </span>
            }
            <div className={classes.root}> 
                <Grid container spacing={2} alignItems="flex-end">
                     <Grid item>
                     <Typography variant="h6"> Notas de la llamada</Typography>
                    </Grid>
                </Grid>
                <Typography variant="body2"> Title*</Typography>
                <Grid container spacing={4} alignItems="flex-end">
                    <Grid item xs>
                        <InputBase 
                        style = { changeNoteInfo.noteTitle == '' ? { border: "1px solid red" }:{ border: "1px solid #d3dbe1" }  }
                        className={classes.noteTitle} 
                        id="noteTitle" 
                        placeholder="Required*"  
                        value={changeNoteInfo.noteTitle} 
                        fullWidth 
                        onBlur={handleOnBlurTextNote}  
                        onChange={handleChangeTextNote} 
                        onFocusCapture={handleFocusTextNote}
                        />
                    </Grid>
                </Grid>
                <Typography variant="body2" className={classes.rootNote}> Description*</Typography>
                <Grid container spacing={4} 
                >
                    <Grid item xs>
                        <TextareaAutosize
                            style ={ changeNoteInfo.noteDescription == '' ? { border: "1px solid red" }:{ border: "1px solid #d3dbe1" }  }
                            className={classes.rootTextAreaBox}
                            value={changeNoteInfo.noteDescription}
                            minRows={4}
                            id="noteDescription" 
                            placeholder= "Required*" 
                            onBlur={handleOnBlurTextNote}
                            onChange={handleChangeTextNote}
                            onFocusCapture={handleFocusTextNote}
                        />
                    </Grid>
                </Grid>
            </div>
        </div>
    );
}