import { h } from 'preact'
import { createStyles, makeStyles } from '@material-ui/core/styles'
import ListItemText from '@material-ui/core/ListItemText'
import ListItemAvatar from '@material-ui/core/ListItemAvatar'
import { Grid } from '@material-ui/core'
import Skeleton from '@material-ui/lab/Skeleton';


const useStyles = makeStyles(() =>
  createStyles({
    phone_content: {
      textAlign: 'right'
    },
    max_text: {
      noWrap: true,
      overflow: 'hidden',
      textOverflow: 'ellipsis',
    },
    gridItem: {
      margin: '-1px',
    },
  }),
);
export default function LoadingListItem() : any
{
     const classes = useStyles();
    return(
        <Grid container spacing={2}
        justifyContent="center"
        alignItems="center" className={classes.gridItem}> 
        <ListItemAvatar>
            <Skeleton variant="circle" width={40} height={40} />
        </ListItemAvatar>
        <ListItemText >
          <Skeleton variant="text" style={{ width: "35%", height: "15px" }}/>
        </ListItemText>
        <Skeleton variant="circle" width={40} height={40} />
      </Grid>
    );
}