import { IAppBarData } from "../../components/appBar/interfaces"
import { IContactCallData } from "../../components/callBar/definition"
import { CrmRecordData } from "../../components/relatedRecordsList/definition"
import packageJson from '../../../package.json'
import { IContactData } from "../../components/contactList/definition"
import { IsavedCall } from "../../views/interfaces"
import { iframePlatformData } from "../types/sugarCrm.modules.types"

export const ContactsData : IContactData[] = [
    { name: "Fernando Huacuja", phoneMobile: "8188593308", phoneWork: "5514627548", phoneHome: "6125436185", type: "Contacts", idCrm: "1234567890" },
    { name: "Ricardo Pelaéz", phoneMobile: "8166539380", phoneWork: "", phoneHome: "", type: "Leads", idCrm: "2345678901" },
    { name: "Pedro Fernandez", phoneMobile: "8113694518", phoneWork: "", phoneHome: "", type: "Contacts", idCrm: "3456789012" },
    { name: "Andrés Rojas", phoneMobile: "8133592213", phoneWork: "", phoneHome: "", type: "Leads", idCrm: "4567890123" },
    { name: "Fernando Alonso", phoneMobile: "5588593308", phoneWork: "", phoneHome: "", type: "Contacts", idCrm: "5678901234" },
    { name: "Fernando Valenzuela", phoneMobile: "5568592248", phoneWork: "", phoneHome: "", type: "Leads", idCrm: "6789012345" },
    { name: "Ramon Ramirez", phoneMobile: "5524598358", phoneWork: "", phoneHome: "", type: "Contacts", idCrm: "7890123456" },
    { name: "Ada Lovelace", phoneMobile: "+13458514328", phoneWork: "", phoneHome: "", type: "User", idCrm: "8901234567" },
    { name: "Marie Curie", phoneMobile: "+13568592389", phoneWork: "", phoneHome: "", type: "Contacts", idCrm: "9012345678" },
    { name: "Pedro Infante", phoneMobile: "5582594368", phoneWork: "", phoneHome: "", type: "Leads", idCrm: "0123456789" }
]
export const callContactsData =
    [
        {
            "id": "8a4da358-2613-11ec-809b-080027405419",
            "date_entered": "2021-10-05T19:36:30+00:00",
            "date_modified": "2021-10-05T19:41:14+00:00",
            "modified_user_id": "7558bcd2-2611-11ec-afc5-080027405419",
            "created_by": "7558bcd2-2611-11ec-afc5-080027405419",
            "description": "",
            "name": "Major Nelson",
            "last_name": "Nelson",
            "phone_home": "",
            "phone_mobile": "5516504476",
            "phone_work": "1457",
            "phone_other": "",
            "phone_fax": "",
            "primary_address_street": "",
            "alt_address_street": "",
            "assistant_phone": "",
            "portal_name": "",
            "commentlog": {
                "records": [],
                "next_offset": {
                    "commentlog_link": -1
                }
            },
            "locked_fields": [],
            "assigned_user_id": "7558bcd2-2611-11ec-afc5-080027405419",
            "team_set_id": "1",
            "acl_team_set_id": "",
            "email": [],
            "_acl": {
                "fields": {}
            },
            "_erased_fields": [],
            "_module": "Contacts",
            "_score": 7.456818,
            "_highlights": {
                "phone_mobile": [
                    "<strong>5516504476</strong>"
                ]
            }
        },
        {
            "id": "1be201fe-1763-4e4d-980f-71b973d971d4",
            "name": "Llamada con +525516504476",
            "date_entered": "2021-10-06T19:17:45+00:00",
            "date_modified": "2021-10-06T19:18:09+00:00",
            "modified_user_id": "7558bcd2-2611-11ec-afc5-080027405419",
            "created_by": "7558bcd2-2611-11ec-afc5-080027405419",
            "description": "CLOSE",
            "date_start": "2021-10-06T19:17:44+00:00",
            "date_end": "2021-10-06T19:18:44+00:00",
            "status": "Planned",
            "commentlog": {
                "records": [],
                "next_offset": {
                    "commentlog_link": -1
                }
            },
            "locked_fields": [],
            "assigned_user_id": "7558bcd2-2611-11ec-afc5-080027405419",
            "team_set_id": "1",
            "acl_team_set_id": "",
            "_acl": {
                "fields": {
                    "transcript": {
                        "create": "no",
                        "write": "no",
                        "license": "no"
                    }
                }
            },
            "_erased_fields": [],
            "_module": "Calls",
            "_score": 3.403061,
            "_highlights": {
                "name": [
                    "Llamada con +<strong>525516504476</strong>"
                ]
            }
        },
        {
            "id": "93b7f6eb-102f-46fe-a45f-e190d07ea4a2",
            "name": "Llamada con +525516504476",
            "date_entered": "2021-10-06T00:10:35+00:00",
            "date_modified": "2021-10-06T00:11:11+00:00",
            "modified_user_id": "7558bcd2-2611-11ec-afc5-080027405419",
            "created_by": "7558bcd2-2611-11ec-afc5-080027405419",
            "description": "cerrar",
            "date_start": "2021-10-06T00:10:35+00:00",
            "date_end": "2021-10-06T00:11:35+00:00",
            "status": "Held",
            "commentlog": {
                "records": [],
                "next_offset": {
                    "commentlog_link": -1
                }
            },
            "locked_fields": [],
            "assigned_user_id": "7558bcd2-2611-11ec-afc5-080027405419",
            "team_set_id": "1",
            "acl_team_set_id": "",
            "_acl": {
                "fields": {
                    "transcript": {
                        "create": "no",
                        "write": "no",
                        "license": "no"
                    }
                }
            },
            "_erased_fields": [],
            "_module": "Calls",
            "_score": 3.403061,
            "_highlights": {
                "name": [
                    "Llamada con +<strong>525516504476</strong>"
                ]
            }
        },
        {
            "id": "c67768a6-c823-48fa-ab1d-814259ed3ae3",
            "name": "Llamada con +525516504476",
            "date_entered": "2021-10-06T18:14:31+00:00",
            "date_modified": "2021-10-06T18:15:48+00:00",
            "modified_user_id": "7558bcd2-2611-11ec-afc5-080027405419",
            "created_by": "7558bcd2-2611-11ec-afc5-080027405419",
            "description": "test",
            "date_start": "2021-10-06T18:14:31+00:00",
            "date_end": "2021-10-06T18:15:31+00:00",
            "status": "Held",
            "commentlog": {
                "records": [],
                "next_offset": {
                    "commentlog_link": -1
                }
            },
            "locked_fields": [],
            "assigned_user_id": "7558bcd2-2611-11ec-afc5-080027405419",
            "team_set_id": "1",
            "acl_team_set_id": "",
            "_acl": {
                "fields": {
                    "transcript": {
                        "create": "no",
                        "write": "no",
                        "license": "no"
                    }
                }
            },
            "_erased_fields": [],
            "_module": "Calls",
            "_score": 3.403061,
            "_highlights": {
                "name": [
                    "Llamada con +<strong>525516504476</strong>"
                ]
            }
        }
    ]


export const dataAppBar : IAppBarData = {
    name: "Cesar", 
    ext: "723", 
    callStatus: "ringing",
    online: true,  
    isAppOpen: true,
    appVersion: packageJson.version,
    callbackConfigOpen: "",
    configOpen: false,
    userAccessData: {} as iframePlatformData
}

export const ContactsDataDummy : IContactData[] = [
    { name: "",  phoneMobile: "", phoneWork: "", phoneHome: "", type: "", idCrm: "1" },
    { name: "",  phoneMobile: "", phoneWork: "", phoneHome: "", type: "", idCrm: "2" },
    { name: "",  phoneMobile: "", phoneWork: "", phoneHome: "", type: "", idCrm: "3" },
]


export const relatedRecords : CrmRecordData[] = [
    {
        "name": "Major Nelson",
        "description": "",
        "module": "Contacts",
        "idCrm": "8a4da358-2613-11ec-809b-080027405419",
        "isRelated": true
    },
    {
        "name": "Llamada con +525516504476",
        "description": "CLOSE",
        "module": "Calls",
        "idCrm": "1be201fe-1763-4e4d-980f-71b973d971d4",
        "isRelated": true
    },
    {
        "name": "Llamada con +525516504476",
        "description": "cerrar",
        "module": "Calls",
        "idCrm": "93b7f6eb-102f-46fe-a45f-e190d07ea4a2",
        "isRelated": false
    },
    {
        "name": "Llamada con +525516504476",
        "description": "test",
        "module": "Calls",
        "idCrm": "c67768a6-c823-48fa-ab1d-814259ed3ae3",
        "isRelated": false
    }
]

export const callContact : IContactCallData = {
        name: "Major Nelson", 
        phoneNumber: "+52 551 650 4476", 
        type: "Co",
        idCrm: "8a4da358-2613-11ec-809b-080027405419",  
}

export const callSaveIdDummy : IsavedCall = {
    id: "1be201fe-1763-4e4d-980f-71b973d971d4",
    name: "Llamada con +525516504476",
    dateCreate: "2021-10-06T19:17:45+00:00",
    description: "CLOSE",
    durationHours: 0,
    durationMinutes: 1,
    dateStart: "2021-10-06T19:17:44+00:00",
    dateEnd: "2021-10-06T19:18:44+00:00",
    module: "Calls"
}